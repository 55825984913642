import { StyleSheet, Text, View } from 'react-native';
import React, { FC, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { STYLE_VARS } from '../../../constants/style-vars';
import { Image } from '../../../components/Image';
import dayjs from 'dayjs';
import { BookingType } from '../types';
import { Button } from '../../../components/Button';
import { COLORS, FONTS } from '../../../constants';
import DatetimeInfo from '../../BookingDetail/components/DatetimeInfo';
import ProductTable from '../../BookingDetail/components/ProductTable';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';

interface BookingCollapseProps {
  //bookingDetail: BookingType;
}

const BookingCollapse: FC<BookingCollapseProps> = ({}) => {
  const [open, setOpen] = useState<boolean>(false);
  const isDesktop = useIsDesktop();

  const renderBottomInfor = useMemo(() => {
    return (
      <View
        style={[
          {
            backgroundColor: '#f9f2f1',
            borderRadius: 18,
          },
          isDesktop()
            ? {
                flexDirection: 'row',
                padding: 20,
                paddingBottom: 10,
                flexWrap: 'wrap',
                gap: 15,
              }
            : {
                flexDirection: 'column',
                gap: 20,
                padding: 16,
              },
        ]}
      >
        <View
          style={[
            isDesktop() && {
              flex: 0.333333,
              minWidth: 200,
            },
          ]}
        >
          <Text style={styles.headerText}>Emergency Contact</Text>
          <Text style={styles.contentInfoText}>lorem.ipsum@gmail.com</Text>
        </View>
        <View
          style={[
            isDesktop() && {
              flex: 0.333333,
              minWidth: 200,
            },
          ]}
        >
          <Text style={styles.headerText}>Contact Number:</Text>
          <Text style={styles.contentInfoText}>+852 2307-1322</Text>
        </View>
        <View
          style={[
            isDesktop() && {
              flex: 0.333333,
              minWidth: 200,
            },
          ]}
        >
          <Text style={styles.headerText}>Time of Payment</Text>
          <Text style={styles.contentInfoText}>11:30 AM</Text>
        </View>
        <View
          style={[
            isDesktop() && {
              flex: 0.333333,
              minWidth: 200,
            },
          ]}
        >
          <Text style={styles.headerText}>Date of Payment</Text>
          <Text style={styles.contentInfoText}>03/12/2024</Text>
        </View>
        <View
          style={[
            isDesktop() && {
              flex: 0.333333,
              minWidth: 200,
            },
          ]}
        >
          <Text style={styles.headerText}>Amount Paid</Text>
          <Text style={styles.contentInfoText}>$688 HKD</Text>
        </View>
        <View
          style={[
            isDesktop() && {
              flex: 0.333333,
              minWidth: 200,
            },
          ]}
        >
          <Text style={styles.headerText}>Booking ID</Text>
          <Text style={styles.contentInfoText}>aBCdEfgH</Text>
        </View>
        <View
          style={[
            isDesktop() && {
              flex: 0.333333,
              minWidth: 200,
            },
          ]}
        >
          <Text style={styles.headerText}>Payment Method</Text>
          <Text style={styles.contentInfoText}>VISA Credit Card **49</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: COLORS.RED_PRIMARY,
              paddingHorizontal: 14,
              paddingVertical: 5,
              borderRadius: 13,
            }}
            onPress={() => {
              setOpen(!open);
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 16,
                color: COLORS.WHITE,
                marginRight: 5,
              }}
            >
              Collapse
            </Text>
            <Image
              source={require('../../../assets/up-arrow.png')}
              style={{
                width: 18,
                height: 10,
                tintColor: COLORS.WHITE,
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }, [isDesktop, open]);

  const renderDatetimeInfor = useMemo(() => {
    return (
      <View
        style={[
          {
            backgroundColor: '#f9f2f1',
            borderRadius: 18,

            paddingTop: 20,
            paddingBottom: 20,
          },
          isDesktop()
            ? {
                flexDirection: 'row',
                paddingHorizontal: 25,
              }
            : {
                flexDirection: 'column',
                gap: 25,
                paddingHorizontal: 20,
              },
        ]}
      >
        <View
          style={[
            {
              flex: 0.333333,
            },
          ]}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '800',
              fontSize: 19,
              letterSpacing: 1,
              marginBottom: 15,
            }}
          >
            Check in:
          </Text>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 16,
              color: COLORS.GREY,
              marginBottom: 13,
            }}
          >
            Fri 22nd March 2024
          </Text>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                paddingHorizontal: 13,
                paddingVertical: 8,
                borderRadius: 18,
                backgroundColor: COLORS.LIGHT_GREY,
              }}
            >
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 12,
                  fontWeight: '500',
                  color: COLORS.BLACK,
                }}
              >
                {'3:00 p.m.'}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={[
            {
              flex: 0.333333,
            },
          ]}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '800',
              fontSize: 19,
              letterSpacing: 1,
              marginBottom: 15,
            }}
          >
            Check out:
          </Text>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 16,
              color: COLORS.GREY,
              marginBottom: 13,
            }}
          >
            Fri 23nd March 2024
          </Text>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                paddingHorizontal: 13,
                paddingVertical: 8,
                borderRadius: 18,
                backgroundColor: COLORS.LIGHT_GREY,
              }}
            >
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 12,
                  fontWeight: '500',
                  color: COLORS.BLACK,
                }}
              >
                {'12:30 p.m.'}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={[
            {
              flex: 0.333333,
            },
          ]}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '800',
              fontSize: 19,
              letterSpacing: 1,
              marginBottom: 15,
            }}
          >
            Number of People:
          </Text>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 16,
              color: COLORS.GREY,
              marginBottom: 13,
            }}
          >
            4 People
          </Text>
        </View>
      </View>
    );
  }, [isDesktop]);

  return (
    <View>
      <View
        style={[
          {
            flexDirection: 'column',
            backgroundColor: COLORS.LIGHT_RED,
            borderRadius: 18,
            paddingTop: 35,
            marginBottom: open && isDesktop() ? 25 : 0,
          },
          isDesktop()
            ? {}
            : {
                paddingHorizontal: 5,
              },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            marginBottom: 15,
          }}
        >
          <View
            style={{
              width: '25%',
            }}
          >
            <Text style={styles.contentText}>28 Jun 2024</Text>
          </View>
          <View
            style={{
              width: '25%',
            }}
          >
            <Text style={styles.contentText}>12345</Text>
          </View>
          <View
            style={{
              width: '25%',
            }}
          >
            <Text style={styles.contentText}>$320 HKD</Text>
          </View>
          <View
            style={{
              width: '25%',
            }}
          >
            <Text
              style={[
                styles.contentText,
                { textTransform: 'uppercase', color: COLORS.LIGHT_GREEN },
              ]}
            >
              PAID
            </Text>
          </View>
        </View>
        {!open && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              paddingBottom: 15,
            }}
          >
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: COLORS.RED_PRIMARY,
                paddingHorizontal: 14,
                paddingVertical: 5,
                borderRadius: 13,
              }}
              onPress={() => {
                setOpen(!open);
              }}
            >
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 16,
                  color: COLORS.WHITE,
                  marginRight: 5,
                }}
              >
                Detail
              </Text>
              <Image
                source={require('../../../assets/down-arrow.png')}
                style={{
                  width: 18,
                  height: 10,
                  tintColor: COLORS.WHITE,
                }}
              />
            </TouchableOpacity>
          </View>
        )}
        {open && (
          <View
            style={{
              paddingHorizontal: isDesktop() ? 20 : 10,
            }}
          >
            {/* <View
              style={{
                flexDirection: 'column',
                backgroundColor: COLORS.WHITE,
                borderRadius: 18,
                borderWidth: 1,
                borderColor: '#DDDDE3',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  paddingTop: 20,
                  justifyContent: 'space-between',
                  borderBottomWidth: 1,
                  borderBottomColor: COLORS.LGREY,
                }}
              >
                <View
                  style={{
                    width: '30%',
                  }}
                >
                  <Text
                    style={[styles.innerTableHeader, { textAlign: 'right' }]}
                  >
                    Camp
                  </Text>
                </View>
                <View
                  style={{
                    width: '30%',
                  }}
                >
                  <Text
                    style={[styles.innerTableHeader, { textAlign: 'center' }]}
                  >
                    No. Of Guests
                  </Text>
                </View>
                <View
                  style={{
                    width: '30%',
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text style={[styles.innerTableHeader]}>Price</Text>
                    <span
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '500',
                        color: COLORS.BLACK,
                        fontSize: 10,
                      }}
                    >
                      {'(HKD)'}
                    </span>
                  </View>
                </View>
              </View>
              <View
                style={[
                  {
                    flexDirection: 'column',
                  },
                  isDesktop()
                    ? {
                        paddingVertical: 20,
                        paddingHorizontal: 27,
                        marginBottom: 20,
                      }
                    : {
                        paddingVertical: 15,
                        paddingHorizontal: 15,
                      },
                ]}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      width: '30%',
                    }}
                  >
                    <View
                      style={{ flexDirection: 'column', gap: 10, width: 110 }}
                    >
                      <Image
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: 18,
                        }}
                        source={require('../../../assets/activities_image/image-1.png')}
                      />
                      <Text
                        style={[styles.innerTableText, { textAlign: 'center' }]}
                      >
                        OVERNIGHT CAMP
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: '30%',
                    }}
                  >
                    <Text
                      style={[styles.innerTableText, { textAlign: 'center' }]}
                    >
                      3
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '30%',
                    }}
                  >
                    <Text
                      style={[styles.innerTableText, { textAlign: 'center' }]}
                    >
                      $580
                    </Text>
                  </View>
                </View>
              </View>
            </View> */}
            {renderDatetimeInfor}
            {open && !isDesktop() && (
              <>
                <ProductTable />
                {renderBottomInfor}
              </>
            )}
          </View>
        )}
      </View>
      {open && isDesktop() && (
        <>
          <ProductTable />
          {renderBottomInfor}
        </>
      )}
    </View>
  );
};

export default BookingCollapse;

const styles = StyleSheet.create({
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.BLACK,
    textAlign: 'center',
  },
  innerTableHeader: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    color: COLORS.BLACK,
    fontSize: 18,
  },
  innerTableText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    color: COLORS.BLACK,
    fontSize: 16,
  },
  headerText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    letterSpacing: 1,
    marginBottom: 15,
  },
  contentInfoText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
});
