import instance from './axios';

const ENDPOINTS = {
  CAMP_TYPES: '/camp-types',
};

const getCampTypes = () => {
  return instance.get(ENDPOINTS.CAMP_TYPES);
};

const CampApi = {
  getCampTypes,
};

export default CampApi;
