import { ActivityIndicator, Modal, StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { LoadingProps } from './Loading.types';
import { COLORS } from '../../constants';

const Loading: FC<LoadingProps> = ({
  style,
  activityIndicatorColor,
  isFullScreen = false,
  ...props
}) => {
  if (isFullScreen) {
    return (
      <Modal visible={true} animationType="fade" transparent={true}>
        <View
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: 'absolute',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <ActivityIndicator
            size="large"
            color={activityIndicatorColor ?? COLORS.RED_PRIMARY}
            testID="activity-indicator"
            {...props}
          />
        </View>
      </Modal>
    );
  }

  return (
    <View style={[styles.loading, style]} testID="loading-view">
      <ActivityIndicator
        size="large"
        color={activityIndicatorColor ?? COLORS.GREEN}
        testID="activity-indicator"
        {...props}
      />
    </View>
  );
};

export default Loading;

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    backgroundColor: COLORS.TRANSPARENT,
    justifyContent: 'center',
  },
});
