import { FC, useCallback, useState } from 'react';
import {
  ScrollView,
  View,
  Image,
  Text,
  TouchableOpacity,
  StyleSheet,
  Switch,
  useWindowDimensions,
} from 'react-native';
import { STYLE_VARS } from '../../constants/style-vars';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { COLORS, FONTS } from '../../constants';
import { NavigationService } from '../../services/navigation';
import { Alert } from '../../services/alert';
import { useDispatch } from 'react-redux';
import { logout } from '../../services/redux/reducers/AuthSlice';

const MyAccount: FC = () => {
  const [pushNoti, setPushNoti] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { height: screenHeight } = useWindowDimensions();

  const renderMyAccountButtons = useCallback(() => {
    const myAccounts = [
      {
        icon: require('../../assets/messages-1.png'),
        title: 'Messages',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'Messages',
          });
        },
      },
      {
        icon: require('../../assets/account-icon.png'),
        title: 'My Profile',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyProfile',
            },
          });
        },
      },
      {
        icon: require('../../assets/booked.png'),
        title: 'My Bookings',
        onPress: () => {
          NavigationService.push<'Root'>('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyBookings',
            },
          });
        },
      },
      {
        icon: require('../../assets/mountain.png'),
        title: 'My Activities',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MyActivities',
          });
        },
      },
      {
        icon: require('../../assets/notice-icon.png'),
        title: 'Notices',
        onPress: () => {
          NavigationService.push<'Root'>('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'Notices',
            },
          });
        },
      },
      {
        icon: require('../../assets/letter-round.png'),
        title: 'Contact Us',
        onPress: () => {
          NavigationService.push<'Root'>('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'ContactUs',
            },
          });
        },
      },
      {
        icon: require('../../assets/logout-icon.png'),
        title: 'Log Out',
        onPress: () => {
          Alert.alert('Logout', 'You want to logout?', [
            {
              text: 'Ok',
              onPress: () => {
                dispatch(logout());
                NavigationService.push('Auth');
              },
            },
            {
              text: 'Cancel',
              onPress: () => {},
            },
          ]);
        },
      },
    ];

    return (
      <View
        style={{
          flexDirection: 'column',
          paddingLeft: 20,
        }}
      >
        {myAccounts.map((item, index) => {
          return (
            <TouchableOpacity
              onPress={item.onPress}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 7,
              }}
            >
              <Image
                source={item.icon}
                style={{
                  width: 24,
                  height: 24,
                  marginRight: 15,
                  tintColor: COLORS.BLACK,
                  resizeMode: 'contain',
                }}
              />
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 20,
                  color: COLORS.GREY,
                  letterSpacing: 0.5,
                }}
              >
                {item.title}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }, [dispatch]);

  const isDesktop = useIsDesktop();

  if (isDesktop()) {
    return <></>;
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            flexDirection: 'row',
            paddingVertical: 20,
            paddingHorizontal: 20,
            justifyContent: 'space-between',
            elevation: 4,
            shadowColor: '#0C0C0D1A',
            shadowOffset: { width: 0, height: 4 },
            shadowOpacity: 1,
            shadowRadius: 4,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 20,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 20,
              }}
            >
              WELCOME,{' '}
            </Text>
            LING CHENG
          </Text>
          <TouchableOpacity>
            <Image
              source={require('../../assets/logout-icon.png')}
              style={{ width: 24, height: 24 }}
            />
          </TouchableOpacity>
        </View>
        <View
          style={{
            paddingHorizontal: 30,
            paddingVertical: 30,
            backgroundColor: '#F9F1F1',
            height: screenHeight,
          }}
        >
          <View style={{ marginBottom: 25 }}>
            <View
              style={{
                paddingBottom: 15,
                borderBottomWidth: 1,
                borderBottomColor: COLORS.BLACK,
                marginBottom: 8,
              }}
            >
              <Text
                style={{
                  fontFamily: FONTS.Arial,
                  fontWeight: '700',
                  fontSize: 20,
                  color: COLORS.BLACK,
                  textTransform: 'uppercase',
                }}
              >
                MY ACCOUNT
              </Text>
            </View>
            {renderMyAccountButtons()}
          </View>
          <View>
            <View
              style={{
                paddingBottom: 15,
                borderBottomWidth: 1,
                borderBottomColor: COLORS.BLACK,
                marginBottom: 8,
              }}
            >
              <Text
                style={{
                  fontFamily: FONTS.Arial,
                  fontWeight: '700',
                  fontSize: 20,
                  color: COLORS.BLACK,
                  textTransform: 'uppercase',
                }}
              >
                SETTINGS
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'column',
                paddingLeft: 20,
              }}
            >
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 7,
                  justifyContent: 'space-between',
                }}
                onPress={() => {
                  NavigationService.push<'Root'>('Root', {
                    screen: 'MenuTab',
                    params: {
                      screen: 'ChangeLanguage',
                    },
                  });
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    source={require('../../assets/language.png')}
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 15,
                      tintColor: COLORS.BLACK,
                    }}
                  />
                  <Text
                    style={{
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '500',
                      fontSize: 20,
                      color: COLORS.GREY,
                      letterSpacing: 0.5,
                    }}
                  >
                    {'Language'}
                  </Text>
                </View>
                <Image
                  source={require('../../assets/usa-circle.png')}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 7,
                  justifyContent: 'space-between',
                }}
                onPress={() => {
                  NavigationService.push<'Root'>('Root', {
                    screen: 'MenuTab',
                    params: {
                      screen: 'PushNotification',
                    },
                  });
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    source={require('../../assets/notification.png')}
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 15,
                      tintColor: COLORS.BLACK,
                    }}
                  />
                  <Text
                    style={{
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '500',
                      fontSize: 20,
                      color: COLORS.GREY,
                      letterSpacing: 0.5,
                    }}
                  >
                    {'Push Notifications'}
                  </Text>
                </View>
                <Switch
                  trackColor={{
                    true: '#34C759',
                    false: STYLE_VARS.fccDarkGrey,
                  }}
                  ios_backgroundColor={STYLE_VARS.fccLightGrey}
                  thumbColor={COLORS.WHITE}
                  onValueChange={(value: boolean) => {
                    setPushNoti(value);
                  }}
                  value={pushNoti}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 7,
                }}
              >
                <Image
                  source={require('../../assets/trash-bin.png')}
                  style={{
                    width: 24,
                    height: 24,
                    marginRight: 15,
                    tintColor: COLORS.BLACK,
                  }}
                />
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 20,
                    color: COLORS.GREY,
                    letterSpacing: 0.5,
                  }}
                >
                  {'Delete My Account'}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default MyAccount;

const styles = StyleSheet.create({});
