import { FC, useCallback, useMemo } from 'react';
import { IEditProfileProps } from '../types';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/redux';
import {
  FormComponent,
  FormStructure,
} from '../../../components/FormHelper/FormHelper.types';
import { ErrorMessage, Field, FieldProps, Formik } from 'formik';
import { StyleSheet, Text, View } from 'react-native';
import InputDatePicker from '../../../components/InputDatePicker/InputDatePicker';
import { COLORS, FONTS } from '../../../constants';
import DropDown from '../../../components/DropDown';
import { TextInput } from '../../../components/TextInput/TextInput';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { NavigationService } from '../../../services/navigation';
import * as yup from 'yup';
import { Button } from '../../../components/Button';
import MobileInput from '../../../components/MobileInput/MobileInput';

const MyProfileForm: FC<IEditProfileProps> = ({ onSubmitHandler }) => {
  const isDesktop = useIsDesktop();
  const { user } = useSelector((redux: RootState) => redux.authReducer);

  const initData = useMemo(() => {
    return {
      email: user?.email,
      phone: `${user?.phone_country},${user?.phone}`,
      mobilePhone: user?.mobile_phone
        ? `${user?.mobile_country},${user?.mobile_phone}`
        : '',
      cardNo: user?.card_id,
      birthday: user?.birthday,
      disabled: '',
      address: user?.address,
      unitNo: user?.unit_no,
      floor: user?.floor,
      buildingNameAndNumber: user?.building,
      streetNameAndNumber: user?.street,
      district: user?.district,
      region: user?.region,
      emergencyFirstName: '',
      emergencyLastName: '',
      emergencyPhone: '',
      emergencyEmail: '',
    };
  }, [user]);

  const editProfileStructure = useMemo(() => {
    const structure: FormStructure = {
      components: [
        {
          label: 'Email',
          name: 'email',
          type: 'text',
          placeholder: 'Enter Email',
          isRequire: true,
        },
        {
          label: 'Phone',
          name: 'phone',
          type: 'mobilePhone',
          placeholder: 'Phone Number',
        },
        {
          label: 'Mobile Phone',
          name: 'mobilePhone',
          type: 'mobilePhone',
          placeholder: 'Phone Number',
          isRequire: true,
          zIndex: 2,
        },
        {
          label: 'HKID Card No',
          name: 'cardNo',
          type: 'text',
          placeholder: '',
          isRequire: true,
          disabled: true,
        },
        {
          label: 'Date of Birth',
          name: 'birthday',
          type: 'datePicker',
          placeholder: 'DD/MM/YYYY',
          isRequire: true,
          zIndex: 1,
        },
        {
          label: 'Disabled',
          name: 'disabled',
          type: 'radio',
          items: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ],
        },
        {
          label: 'Address',
          name: 'address',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Unit No',
          name: 'unitNo',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Floor',
          name: 'floor',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Building Name and Number',
          name: 'buildingNameAndNumber',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Street Name and Number',
          name: 'streetNameAndNumber',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'District',
          name: 'district',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Region',
          name: 'region',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Emergency Contact (First Name)',
          name: 'emergencyFirstName',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Emergency Contact (Last Name)',
          name: 'emergencyLastName',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Emergency Contact (Phone)',
          name: 'emergencyPhone',
          type: 'mobilePhone',
          placeholder: 'Phone Number',
          isRequire: true,
        },
        {
          label: 'Emergency Contact (Email)',
          name: 'emergencyEmail',
          type: 'text',
          isRequire: true,
        },
      ],
    };

    return structure;
  }, []);

  const renderForm = useCallback(
    (component: FormComponent) => {
      switch (component.type) {
        case 'datePicker':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={[{ zIndex: 1, width: '100%' }]}>
                  <InputDatePicker
                    date={field.value}
                    onChangeDate={(date) => {
                      field.onChange(field.name)(date);
                    }}
                    placeholder={component.placeholder}
                    inputStyle={{
                      width: '100%',
                      backgroundColor: COLORS.WHITE,
                    }}
                  />
                </View>
              )}
            </Field>
          );
        case 'dropdown':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={[!isDesktop() && { width: '100%' }]}>
                  <DropDown
                    items={component.items || []}
                    placeholder={component.placeholder}
                    zIndex={component.zIndex}
                    dropdownStyle={{
                      borderColor: '#DDDDE3',
                      width: isDesktop() ? 400 : '100%',
                      borderRadius: 6,
                      padding: 14,
                    }}
                    textStyle={{
                      color: COLORS.GREY,
                      fontSize: 16,
                      fontFamily: FONTS.AvenirMedium,
                      letterSpacing: 1,
                    }}
                    dropDownContainerTextStyle={{
                      color: COLORS.DARKER_GREY,
                    }}
                    buttonStyle={{
                      tintColor: COLORS.DARKER_GREY,
                    }}
                    setValues={(value) => {
                      field.onChange(field.name)(value.value.toString());
                    }}
                    title={component.titleDropdown}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        case 'mobilePhone':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={[{ width: '100%' }]}>
                  <MobileInput
                    setValues={(value) => {
                      field.onChange(field.name)(value);
                    }}
                    textInputStyle={{
                      width: '100%',
                    }}
                    initValue={field.value}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        case 'radio':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View>
                  <RadioButton
                    options={component.items ? component.items : []}
                    selectedOption={field.value}
                    onSelect={(value) => {
                      field.onChange(field.name)(value.toString());
                    }}
                    radioStyle={{ justifyContent: 'space-around' }}
                  />
                </View>
              )}
            </Field>
          );
        default:
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View
                  style={{
                    width: '100%',
                  }}
                >
                  <TextInput
                    style={{
                      width: '100%',
                      borderWidth: 1,
                      borderColor: '#DDDDE3',
                      borderStyle: 'solid',
                      padding: 12,
                      letterSpacing: 1.2,
                      borderRadius: 6,
                      fontSize: 16,
                      backgroundColor: component.disabled
                        ? COLORS.LIGHT_GREY
                        : 'white',
                      fontFamily: FONTS.AvenirMedium,
                    }}
                    value={field.value}
                    onChangeText={(text) => {
                      field.onChange(field.name)(text);
                    }}
                    placeholder={component.placeholder}
                    placeholderTextColor={COLORS.GREY}
                    editable={!component.disabled}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
      }
    },
    [isDesktop]
  );

  return (
    <View
      style={{
        backgroundColor: COLORS.LIGHT_RED,
        padding: 25,
        width: '100%',
        borderRadius: 18,
        flexDirection: 'column',
        marginBottom: 25,
      }}
    >
      <Text
        style={{
          fontFamily: FONTS.Arial,
          fontWeight: '700',
          fontSize: 18,
          letterSpacing: 1.5,
          textTransform: 'uppercase',
          marginBottom: 15,
        }}
      >
        My Profile
      </Text>
      <View
        style={{
          flexDirection: 'row',
          marginBottom: 20,
        }}
      >
        <View
          style={{
            width: '50%',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View style={{ width: '40%' }}>
            <Text style={styles.contentText}>First Name:</Text>
          </View>
          <View style={{ width: '60%' }}>
            <Text style={styles.contentText}>{user?.first_name}</Text>
          </View>
        </View>
        <View
          style={{
            width: '50%',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View style={{ width: '40%' }}>
            <Text style={styles.contentText}>Last Name:</Text>
          </View>
          <View style={{ width: '60%' }}>
            <Text style={styles.contentText}>{user?.last_name}</Text>
          </View>
        </View>
      </View>
      <Formik
        initialValues={initData}
        onSubmit={(value) => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyProfile',
            },
          });
        }}
        validationSchema={yup.object().shape({})}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <>
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                zIndex: 1,
                justifyContent: 'space-between',
              }}
            >
              {editProfileStructure.components.map((component) => (
                <View
                  style={[
                    {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginBottom: 20,
                      zIndex: component.zIndex ? component.zIndex : 0,
                    },
                    isDesktop()
                      ? {
                          width: '48%',
                        }
                      : {
                          width: '100%',
                        },
                  ]}
                >
                  <Text
                    style={{
                      fontFamily: FONTS.AvenirMedium,
                      fontSize: 16,
                      color: COLORS.GREY,
                      marginBottom: 20,
                    }}
                  >
                    {component.label}
                    {component.isRequire && (
                      <Text
                        style={{
                          color: COLORS.RED_PRIMARY,
                        }}
                      >
                        *
                      </Text>
                    )}
                    :
                  </Text>
                  {renderForm(component)}
                </View>
              ))}
            </View>
            <Button
              style={{
                backgroundColor: COLORS.RED_PRIMARY,
                paddingVertical: 15,
                borderRadius: 16,
                width: 270,
                overflow: 'hidden',
              }}
              className=""
              textStyle={{
                color: COLORS.WHITE,
                fontSize: 19,
                fontWeight: '800',
                fontFamily: FONTS.AvenirMedium,
                textTransform: 'uppercase',
              }}
              text="Save"
              arrowRight={false}
              onPress={() => {
                NavigationService.push('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'MyProfile',
                  },
                });
              }}
            />
          </>
        )}
      </Formik>
    </View>
  );
};

export default MyProfileForm;

const styles = StyleSheet.create({
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
});
