import { FC, useCallback, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { Footer } from '../../components/Footer';
import CreateAccountForm from './components/CreateAccountForm';
import { ICreateAccountState } from './types';
import VerifyCode from './components/VerifyCode';
import { IFormData } from '../../components/FormHelper/FormHelper.types';
import { AuthApi } from '../../services/api/auth.api';
import { Alert } from '../../services/alert';
import { Loading } from '../../components';
import { NavigationService } from '../../services/navigation';
import { ForgotPassword } from './components/ForgotPassword';

const initialCreateAccountState: ICreateAccountState = {
  mode: 'reset',
  email: 'linhduong.pscd@gmail.com',
  mobile: '',
};

const CreateAnAccount: FC = () => {
  const [state, setState] = useState(initialCreateAccountState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateState = useCallback(
    (state: Partial<ICreateAccountState>) =>
      setState((prevState) => ({ ...prevState, ...state })),
    [setState]
  );

  const onSubmitCreate = useCallback(
    async (data: IFormData) => {
      setIsLoading(true);
      AuthApi.register(data)
        .then((value) => {
          updateState({
            mode: data.otp_type,
            email: data.email,
            mobile: data.sms,
          });
        })
        .catch((error) => {
          Alert.alert('Error', error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [updateState]
  );

  const onSubmitVerify = useCallback(async (data: IFormData) => {
    if (!data.password || data.password === '') {
      Alert.alert('Error', 'Password is empty');
    } else if (data.password !== data.password_confirmation) {
      Alert.alert('Error', "Passwords don't match");
    } else if (data.password.length < 8) {
      Alert.alert('Error', 'Password must be at least 8 characters long');
    } else if (!/[A-Z]/.test(data.password)) {
      Alert.alert(
        'Error',
        'At least one capital letter is required in the password'
      );
    } else if (!/[0-9\W_]/.test(data.password)) {
      Alert.alert(
        'Error',
        'At least one number or special character is required in the password'
      );
    } else {
      setIsLoading(true);
      AuthApi.registerValidation(data)
        .then((value) => {
          Alert.alert(
            'Success',
            'The password has been successfully updated. You may now log in.'
          );
        })
        .catch((error) => {
          Alert.alert('Error', error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const onSubmitForgot = useCallback(
    async (data: IFormData) => {
      if (data.email !== '') {
        setIsLoading(true);
        AuthApi.forgotPassword({ login: data.email })
          .then((value) => {
            updateState({
              mode: 'email',
            });
          })
          .catch((error) => {
            Alert.alert('Error', error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [updateState]
  );

  const onSubmitForm = useCallback(
    async (data: IFormData) => {
      if (state.mode == 'forgot') {
        if (data.email !== '') {
          setIsLoading(true);
          AuthApi.forgotPassword({ email: data.email })
            .then((value) => {
              updateState({
                mode: 'reset',
                email: data.email,
              });
            })
            .catch((error) => {
              Alert.alert('Error', error.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } else if (
        state.mode == 'reset' ||
        state.mode == 'sms' ||
        state.mode == 'email'
      ) {
        if (!data.password || data.password === '') {
          Alert.alert('Error', 'Password is empty');
        } else if (data.password !== data.password_confirmation) {
          Alert.alert('Error', "Passwords don't match");
        } else if (data.password.length < 8) {
          Alert.alert('Error', 'Password must be at least 8 characters long');
        } else if (!/[A-Z]/.test(data.password)) {
          Alert.alert(
            'Error',
            'At least one capital letter is required in the password'
          );
        } else if (!/[0-9\W_]/.test(data.password)) {
          Alert.alert(
            'Error',
            'At least one number or special character is required in the password'
          );
        } else {
          setIsLoading(true);
          if (state.mode == 'reset') {
            AuthApi.resetPassword(data)
              .then((value) => {
                Alert.alert(
                  'Success',
                  'The password has been successfully updated. You may now log in.'
                );
              })
              .catch((error) => {
                Alert.alert('Error', error.message);
              })
              .finally(() => {
                setIsLoading(false);
              });
          } else {
            AuthApi.registerValidation(data)
              .then((value) => {
                Alert.alert(
                  'Success',
                  'The password has been successfully updated. You may now log in.'
                );
              })
              .catch((error) => {
                Alert.alert('Error', error.message);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        }
      }
    },
    [updateState, state]
  );

  return (
    <ScrollView>
      {state.mode === 'form' && (
        <CreateAccountForm
          state={state}
          setState={updateState}
          onSubmitHandler={onSubmitCreate}
        />
      )}
      {(state.mode === 'email' ||
        state.mode === 'sms' ||
        state.mode == 'reset') && (
        <VerifyCode
          state={state}
          setState={updateState}
          onSubmitHandler={onSubmitForm}
        />
      )}
      {state.mode === 'forgot' && (
        <ForgotPassword
          state={state}
          setState={updateState}
          onSubmitHandler={onSubmitForm}
        />
      )}
      <Footer />
      {isLoading && <Loading isFullScreen={true} />}
    </ScrollView>
  );
};

export default CreateAnAccount;

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 15,
    borderRadius: 13,
    width: 300,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
