import { FC, useEffect } from 'react';
import { Image, Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/redux';
import { format } from 'date-fns';
import Sidebar from '../../../components/Sidebar';
import { DASHBOARD_DESTOP_MENU } from '../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { homeStyles } from '../styles';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import {
  fetchDate,
  fetchWeather,
} from '../../../services/redux/reducers/ActionCreators';

export const User: FC = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { dateData } = useSelector((state: RootState) => state?.dateReducer);
  const { user, loggedin } = useSelector(
    (state: RootState) => state?.authReducer
  );
  const { weatherData, icon } = useSelector(
    (state: RootState) => state?.weatherReducer
  );

  useEffect(() => {
    dispatch(fetchDate());
    dispatch(fetchWeather());
  }, [dispatch]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return format(date, 'EE, dd MMM');
  };

  const isDesktop = useIsDesktop();

  if (isDesktop()) {
    return (
      <View
        style={{
          width: '100%',
          minWidth: 230,
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.31,
          shadowRadius: 4,
          borderRadius: 18,
          overflow: 'hidden',
          backgroundColor: COLORS.WHITE,
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 25,
          }}
        >
          {loggedin && (
            <View
              style={{
                paddingBottom: 25,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: FONTS.AvenirMedium,
                  lineHeight: 22,
                  color: COLORS.GREY,
                }}
              >
                Welcome
              </Text>
              <Text
                style={{
                  fontSize: 18,
                  fontFamily: FONTS.AvenirMedium,
                  textTransform: 'uppercase',
                  lineHeight: 26,
                  color: COLORS.BLACK,
                }}
              >
                {`${user?.first_name} ${user?.last_name}`}
              </Text>
            </View>
          )}

          <View
            style={{
              marginBottom: 20,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 18,
                fontFamily: FONTS.AvenirMedium,
                color: COLORS.BLACK,
              }}
            >
              {formatDate(dateData?.dateTime)}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Image
                source={icon}
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 12,
                  tintColor: COLORS.BLACK,
                }}
              />
              <Text
                style={{
                  fontSize: 28,
                  color: COLORS.RED_PRIMARY,
                  fontFamily: FONTS.AvenirMedium,
                }}
              >
                {weatherData?.current_weather?.temperature}˚
              </Text>
            </View>
          </View>
          <View
            style={{
              backgroundColor: COLORS.RED_PRIMARY,
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <Sidebar
              data={DASHBOARD_DESTOP_MENU}
              current={-1}
              //handlePressItem={navigateToLogin}
            />
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={homeStyles.menuuserwrap}>
      <View style={homeStyles.menuuserinfo}>
        <Text style={homeStyles.welcomeText}>WELCOME</Text>
        <Text style={[homeStyles.userName, { textTransform: 'uppercase' }]}>
          {'LING CHENG'}
        </Text>
      </View>

      <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontSize: 20,
            color: COLORS.BLACK,
            textTransform: 'uppercase',
          }}
        >
          {formatDate(new Date().toString())}
        </Text>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image
            source={icon}
            style={{
              width: 20,
              height: 20,
              marginRight: 12,
              tintColor: COLORS.BLACK,
            }}
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 20,
              color: COLORS.RED_PRIMARY,
            }}
          >
            {weatherData?.current_weather?.temperature}˚C
          </Text>
        </View>
      </View>
    </View>
  );
};
