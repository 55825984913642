import { FC, useEffect, useState } from 'react';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { Button } from '../../components/Button';
import { STYLE_VARS } from '../../constants/style-vars';
import { Footer } from '../../components/Footer';
import { NavigationService } from '../../services/navigation';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { useDispatch } from 'react-redux';
import { updateRoute } from '../../services/redux/route';

const IntroDesktop: FC = () => {
  const [type, setType] = useState<'language' | 'auth'>('language');
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateRoute({ name: 'IntroDesktop' }));
  }, [dispatch]);

  return (
    <ScrollView>
      <View
        style={{
          position: 'relative',
        }}
      >
        <Image
          source={require('../../assets/home-page-banner.jpeg')}
          style={{
            height: 200,
            resizeMode: 'cover',
          }}
        />
        <Text
          style={[
            {
              textTransform: 'uppercase',
              fontFamily: FONTS.AvenirMedium,

              color: COLORS.BLACK,
              textAlign: 'center',
              marginBottom: 70,
              position: 'absolute',
              top: isDesktop() ? 60 : 40,
              left: 0,
              right: 0,
            },
            isDesktop()
              ? {
                  fontSize: 26,
                  top: 60,
                  letterSpacing: 2,
                }
              : {
                  fontSize: 20,
                  top: 40,
                  fontWeight: '700',
                  letterSpacing: 1,
                },
          ]}
        >
          Book A Camp At Helping Hand Holiday Centre
        </Text>
      </View>
      <View
        style={{
          paddingTop: 100,
          minHeight: 600,
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {type === 'language' ? (
            <>
              <Button
                style={styles.buttonStyle}
                className=""
                arrowRight={false}
                textStyle={styles.textButtonStyle}
                text="中文"
                onPress={() => {
                  setType('auth');
                }}
              />
              <Button
                style={styles.buttonStyle}
                arrowRight={false}
                textStyle={styles.textButtonStyle}
                className=""
                text={'ENGLISH'}
                onPress={() => {
                  setType('auth');
                }}
              />
            </>
          ) : (
            <>
              <Button
                style={styles.buttonStyle}
                className=""
                arrowRight={false}
                textStyle={styles.textButtonStyle}
                text={'Create an account'}
                onPress={() => {
                  NavigationService.push('Auth', {
                    screen: 'create',
                  });
                }}
              />
              <Button
                style={styles.buttonStyle}
                arrowRight={false}
                textStyle={styles.textButtonStyle}
                className=""
                onPress={() => {
                  NavigationService.push('Auth');
                }}
                text={'Sign in'}
              />
            </>
          )}
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default IntroDesktop;

const styles = StyleSheet.create({
  titleText: {
    textTransform: 'uppercase',
    fontSize: 30,
    fontFamily: FONTS.Arial,
    textAlign: 'center',
    fontWeight: '700',
    lineHeight: 35,
    marginBottom: 60,
  },
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 15,
    borderRadius: 13,
    width: 300,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
  },
});
