import { FC, useState } from 'react';
import { IAuthProps } from '../types';
import {
  Image,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { COLORS, FONTS } from '../../../constants';
import { STYLE_VARS } from '../../../constants/style-vars';
import { authStyles } from '../styles';
import { Button } from '../../../components/Button';
import { NavigationService } from '../../../services/navigation';
import { useDispatch } from 'react-redux';

export const Login: FC<IAuthProps> = ({ state, setState, onSubmitHandler }) => {
  const isDesktop = useIsDesktop();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isHidePassword, setIsHidePassword] = useState<boolean>(true);
  const dispatch = useDispatch();

  return (
    <View>
      <View
        style={{
          paddingTop: isDesktop() ? 70 : 20,
        }}
      >
        <Text
          style={{
            color: COLORS.BLACK,
            marginBottom: 25,
            fontFamily: FONTS.Arial,
            fontSize: 30,
            letterSpacing: 1.5,
            fontWeight: '700',
            textAlign: isDesktop() ? 'left' : 'center',
          }}
        >
          LOGIN
        </Text>
        <View>
          <TextInput
            style={[
              (isDesktop()
                ? STYLE_VARS.textinputweb
                : STYLE_VARS.textinput) as TextStyle,
              {
                marginBottom: 15,
              },
            ]}
            value={username}
            onChangeText={(text) => {
              setUsername(text);
            }}
            placeholder={'USERNAME'}
            placeholderTextColor={COLORS.GREY}
          />
          <View style={{ marginBottom: 20 }}>
            <TextInput
              style={[
                (isDesktop()
                  ? STYLE_VARS.textinputweb
                  : STYLE_VARS.textinput) as TextStyle,
                authStyles.inputWithNote,
              ]}
              value={password}
              onChangeText={(text) => {
                setPassword(text);
              }}
              placeholder={'PASSWORD'}
              placeholderTextColor={COLORS.GREY}
              secureTextEntry={isHidePassword}
              onSubmitEditing={() => {
                if (username && password && onSubmitHandler) {
                  onSubmitHandler({
                    email: username,
                    password: password,
                  });
                }
              }}
            />
            <TouchableOpacity
              style={[
                {
                  position: 'absolute',
                  right: 14,
                  top: 15,
                },
                isDesktop() && {
                  left: 364,
                  top: 14,
                },
              ]}
              onPress={() => {
                setIsHidePassword(!isHidePassword);
              }}
            >
              <Image
                style={{
                  width: 20,
                  height: 20,
                  tintColor: COLORS.RED_PRIMARY,
                }}
                source={
                  isHidePassword
                    ? require('../../../assets/hidden-eye.png')
                    : require('../../../assets/eye.png')
                }
              />
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ marginBottom: 15 }}>
          <Button
            style={{
              backgroundColor: COLORS.RED_PRIMARY,
              paddingVertical: 15,
              borderRadius: 13,
              width: 300,
              marginBottom: 20,
              overflow: 'hidden',
            }}
            className=""
            arrowRight={false}
            textStyle={{
              color: COLORS.WHITE,
              fontSize: 17,
              fontFamily: FONTS.AvenirMedium,
              textTransform: 'uppercase',
              fontWeight: '500',
              letterSpacing: 1,
            }}
            text="Submit"
            onPress={() => {
              if (username && password && onSubmitHandler) {
                onSubmitHandler({
                  email: username,
                  password: password,
                });
              }
            }}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'column',
          alignItems: isDesktop() ? 'flex-start' : 'center',
        }}
      >
        <TouchableOpacity
          style={styles.link}
          onPress={() => {
            setState({
              mode: 'create',
            });
          }}
        >
          <Text style={styles.linkText}>SIGN UP</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.link}
          onPress={() => {
            setState({
              mode: 'forgotPassword',
            });
          }}
        >
          <Text style={styles.linkText}>FORGOT PASSWORD</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  link: {
    marginBottom: 7,
  },
  linkText: {
    fontFamily: FONTS.AvenirRegular,
    fontSize: 16,
    color: COLORS.GREY,
    textTransform: 'uppercase',
  },
});
