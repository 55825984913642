import { FC, useState } from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { MAX_WIDTH } from '../../../constants/platform';
import { NavigationService } from '../../../services/navigation';
import { COLORS, FONTS } from '../../../constants';
import { Button } from '../../../components/Button';
import { TextInput } from '../../../components/TextInput/TextInput';
import { STYLE_VARS } from '../../../constants/style-vars';
import { IAuthProps } from '../types';

export const ForgotPassword: FC<IAuthProps> = ({
  state,
  setState,
  onSubmitHandler,
}) => {
  const isDesktop = useIsDesktop();
  const [email, setEmail] = useState<string>('');

  return (
    <View
      style={[
        isDesktop()
          ? {
              maxWidth: MAX_WIDTH,
              width: '100%',
              alignSelf: 'center',
              marginBottom: 35,
            }
          : {},
      ]}
    >
      <View
        style={{
          paddingTop: isDesktop() ? 70 : 20,
        }}
      >
        <Text
          style={{
            color: COLORS.BLACK,
            marginBottom: 25,
            fontFamily: FONTS.Arial,
            fontSize: 30,
            letterSpacing: 1.5,
            fontWeight: '700',
            textAlign: isDesktop() ? 'left' : 'center',
          }}
        >
          FORGOT PASSWORD
        </Text>
        <View style={{ marginBottom: 10 }}>
          <TextInput
            style={[
              (isDesktop()
                ? STYLE_VARS.textinputweb
                : STYLE_VARS.textinput) as TextStyle,
              {
                marginBottom: 15,
              },
            ]}
            value={email}
            onChangeText={(text) => {
              setEmail(text);
            }}
            placeholder={'PLEASE ENTER EMAIL'}
            placeholderTextColor={COLORS.GREY}
          />
        </View>
        <View style={{ marginBottom: 10 }}>
          <Button
            style={{
              backgroundColor: COLORS.RED_PRIMARY,
              paddingVertical: 15,
              borderRadius: 13,
              width: 300,
              marginBottom: 20,
              overflow: 'hidden',
            }}
            className=""
            arrowRight={false}
            textStyle={{
              color: COLORS.WHITE,
              fontSize: 17,
              fontFamily: FONTS.AvenirMedium,
              textTransform: 'uppercase',
              fontWeight: '500',
              letterSpacing: 1,
            }}
            text="Submit"
            onPress={() => {
              if (onSubmitHandler) {
                onSubmitHandler({
                  email: email,
                });
              }
            }}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'column',
          alignItems: isDesktop() ? 'flex-start' : 'center',
        }}
      >
        <TouchableOpacity
          style={styles.link}
          onPress={() => {
            setState({
              mode: 'login',
            });
          }}
        >
          <Text style={styles.linkText}>LOGIN</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.link}
          onPress={() => {
            setState({
              mode: 'create',
            });
          }}
        >
          <Text style={styles.linkText}>SIGN UP</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  link: {
    marginBottom: 7,
  },
  linkText: {
    fontFamily: FONTS.AvenirRegular,
    fontSize: 16,
    color: COLORS.GREY,
    textTransform: 'uppercase',
  },
});
