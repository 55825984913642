import { FC, useMemo, useState } from 'react';
import {
  Image,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { STYLE_VARS } from '../../constants/style-vars';
import { NavigationService } from '../../services/navigation';
import { Alert } from '../../services/alert';
import { useDispatch } from 'react-redux';
import { logout } from '../../services/redux/reducers/AuthSlice';

const MyAccountMenu: FC = () => {
  const [pushNoti, setPushNoti] = useState<boolean>(false);
  const dispatch = useDispatch();

  const renderMyAccountButtons = useMemo(() => {
    const myAccounts = [
      {
        icon: require('../../assets/account-icon.png'),
        title: 'My Profile',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyProfile',
            },
          });
        },
      },
      {
        icon: require('../../assets/messages-1.png'),
        title: 'Messages',
        onPress: () => {
          NavigationService.push<'Root'>('Root', {
            screen: 'Messages',
          });
        },
      },
      {
        icon: require('../../assets/booked.png'),
        title: 'My Bookings',
        onPress: () => {
          NavigationService.push<'Root'>('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyBookings',
            },
          });
        },
      },
      {
        icon: require('../../assets/mountain.png'),
        title: 'My Activities',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MyActivities',
          });
        },
      },
      {
        icon: require('../../assets/notice-icon.png'),
        title: 'Notices',
        onPress: () => {
          NavigationService.push<'Root'>('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'Notices',
            },
          });
        },
      },
    ];

    return (
      <View
        style={{
          flexDirection: 'column',
        }}
      >
        {myAccounts.map((item, index) => {
          return (
            <TouchableOpacity
              onPress={item.onPress}
              style={styles.buttonWrapper}
            >
              <Image source={item.icon} style={styles.buttonIcon} />
              <Text style={styles.buttonText}>{item.title}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }, []);

  return (
    <View
      style={{
        width: '100%',
        minWidth: 230,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.31,
        shadowRadius: 4,
        borderRadius: 18,
        overflow: 'hidden',
        backgroundColor: COLORS.WHITE,
        marginBottom: 30,
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            paddingVertical: 25,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontFamily: FONTS.AvenirMedium,
              lineHeight: 22,
              color: COLORS.GREY,
            }}
          >
            Welcome
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontFamily: FONTS.AvenirMedium,
              textTransform: 'uppercase',
              lineHeight: 26,
              color: COLORS.BLACK,
            }}
          >
            Ling Cheng
          </Text>
        </View>
        <View
          style={{
            paddingTop: 25,
            paddingLeft: 20,
            backgroundColor: '#F9F1F1',
            width: '100%',
            paddingBottom: 15,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '600',
              fontSize: 19,
              letterSpacing: 1.5,
              textTransform: 'uppercase',
              borderBottomWidth: 1,
              paddingBottom: 15,
              marginBottom: 12,
            }}
          >
            My Account
          </Text>
          <View
            style={{
              marginBottom: 40,
            }}
          >
            {renderMyAccountButtons}
          </View>
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '600',
              fontSize: 19,
              letterSpacing: 1.5,
              textTransform: 'uppercase',
              borderBottomWidth: 1,
              paddingBottom: 15,
              marginBottom: 12,
            }}
          >
            Settings
          </Text>
          <View style={styles.groupButtonWrapper}>
            <TouchableOpacity
              style={[
                styles.buttonWrapper,
                {
                  justifyContent: 'space-between',
                },
              ]}
              onPress={() => {
                NavigationService.push<'Root'>('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'ChangeLanguage',
                  },
                });
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Image
                  source={require('../../assets/language.png')}
                  style={styles.buttonIcon}
                />
                <Text style={styles.buttonText}>{'Language'}</Text>
              </View>
              <Image
                source={require('../../assets/usa-circle.png')}
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.buttonWrapper,
                {
                  justifyContent: 'space-between',
                },
              ]}
              onPress={() => {
                NavigationService.push<'Root'>('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'PushNotification',
                  },
                });
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Image
                  source={require('../../assets/notification.png')}
                  style={styles.buttonIcon}
                />
                <Text style={styles.buttonText}>{'Push Notifications'}</Text>
              </View>
              <Switch
                trackColor={{
                  true: '#34C759',
                  false: STYLE_VARS.fccDarkGrey,
                }}
                ios_backgroundColor={STYLE_VARS.fccLightGrey}
                thumbColor={COLORS.WHITE}
                onValueChange={(value: boolean) => {
                  setPushNoti(value);
                }}
                value={pushNoti}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                NavigationService.push<'Root'>('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'DeleteAccount',
                  },
                });
              }}
              style={styles.buttonWrapper}
            >
              <Image
                source={require('../../assets/trash-bin.png')}
                style={styles.buttonIcon}
              />
              <Text style={styles.buttonText}>{'Delete My Account'}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                NavigationService.push<'Root'>('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'ContactUs',
                  },
                });
              }}
              style={styles.buttonWrapper}
            >
              <Image
                source={require('../../assets/letter-round.png')}
                style={styles.buttonIcon}
              />
              <Text style={styles.buttonText}>{'Contact Us'}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Alert.alert('Logout', 'You want to logout?', [
                  {
                    text: 'Ok',
                    onPress: () => {
                      dispatch(logout());
                      NavigationService.push('Auth');
                    },
                  },
                  {
                    text: 'Cancel',
                    onPress: () => {},
                  },
                ]);
              }}
              style={styles.buttonWrapper}
            >
              <Image
                source={require('../../assets/logout-icon.png')}
                style={styles.buttonIcon}
              />
              <Text style={styles.buttonText}>{'Log Out'}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

export default MyAccountMenu;

const styles = StyleSheet.create({
  buttonWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
  },
  buttonIcon: {
    width: 24,
    height: 24,
    marginRight: 15,
    tintColor: '#504f50',
    resizeMode: 'contain',
  },
  buttonText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 18,
    color: COLORS.GREY,
    letterSpacing: 0.5,
  },
  groupButtonWrapper: {
    flexDirection: 'column',
    paddingRight: 7,
  },
});
