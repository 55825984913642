import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ICreateAccountProps } from '../types';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import {
  FormComponent,
  FormStructure,
} from '../../../components/FormHelper/FormHelper.types';
import { ErrorMessage, Field, FieldProps, Formik } from 'formik';
import { StyleSheet, Text, TextStyle, View } from 'react-native';
import InputDatePicker from '../../../components/InputDatePicker/InputDatePicker';
import DropDown from '../../../components/DropDown';
import { COLORS, FONTS } from '../../../constants';
import { TextInput } from '../../../components/TextInput/TextInput';
import { STYLE_VARS } from '../../../constants/style-vars';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { authStyles } from '../../Auth/styles';
import { MAX_WIDTH } from '../../../constants/platform';
import * as yup from 'yup';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { Button } from '../../../components/Button';
import { IUserType } from '../../../types/auth.types';
import { AuthApi } from '../../../services/api/auth.api';
import MobileInput from '../../../components/MobileInput/MobileInput';

export const CreateAccountForm: FC<ICreateAccountProps> = ({
  state,
  setState,
  onSubmitHandler,
}) => {
  const initData = useMemo(() => {
    return {
      first_name: '',
      last_name: '',
      user_type_id: '',
      email: '',
      phone: '',
      otp_type: '',
      birthday: '',
      is_wheelchair: '',
      card_id: '',
      terms: '',
    };
  }, []);
  const isDesktop = useIsDesktop();
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [userTypes, setUserTypes] = useState<IUserType[]>([]);

  const getUserType = useCallback(() => {
    AuthApi.getUserTypes()
      .then((res) => {
        setUserTypes(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }, []);

  const userTypeItems = useMemo(() => {
    return userTypes.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }, [userTypes]);

  const createAccountStructure = useMemo(() => {
    const structure: FormStructure = {
      components: [
        {
          label: 'First Name',
          name: 'first_name',
          type: 'text',
          placeholder: 'Enter First Name',
          isRequire: true,
        },
        {
          label: 'Last Name',
          name: 'last_name',
          type: 'text',
          placeholder: 'Enter Last Name',
          isRequire: true,
        },
        {
          name: 'user_type_id',
          type: 'dropdown',
          label: 'Account Category',
          items: userTypeItems,
          zIndex: 3,
          titleDropdown: 'Please Select',
          placeholder: 'Select Item',
          isRequire: true,
        },
        {
          label: 'Email Address',
          name: 'email',
          type: 'text',
          placeholder: 'Enter Email',
          isRequire: true,
        },
        {
          label: 'Mobile Number',
          name: 'phone',
          type: 'mobilePhone',
          placeholder: 'Enter Phone Number',
          isRequire: true,
          zIndex: 2,
        },
        {
          label: 'One Time Password will send to:',
          name: 'otp_type',
          type: 'radio',
          items: [
            { label: 'Email', value: 'email' },
            { label: 'Mobile Number', value: 'sms' },
          ],
        },
        {
          label: 'Date of Birth',
          name: 'birthday',
          type: 'datePicker',
          placeholder: 'Enter Date of Birth',
          isRequire: true,
          zIndex: 1,
        },
        {
          label: 'Are You A Wheelchair User?',
          name: 'is_wheelchair',
          type: 'radio',
          items: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        {
          label: 'HKID Card number',
          name: 'card_id',
          type: 'text',
          placeholder: 'Enter HKID Card number',
          isRequire: true,
        },
      ],
    };

    return structure;
  }, [userTypeItems]);

  const renderForm = useCallback(
    (component: FormComponent) => {
      switch (component.type) {
        case 'datePicker':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View
                  style={[{ zIndex: 1 }, !isDesktop() && { width: '100%' }]}
                >
                  <InputDatePicker
                    date={field.value}
                    onChangeDate={(date) => {
                      field.onChange(field.name)(date);
                    }}
                    placeholder={component.placeholder}
                    style={{
                      marginBottom: 0,
                    }}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        case 'dropdown':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={[!isDesktop() && { width: '100%' }]}>
                  <DropDown
                    items={component.items || []}
                    placeholder={component.placeholder}
                    zIndex={component.zIndex}
                    dropdownStyle={{
                      borderColor: '#DDDDE3',
                      width: isDesktop() ? 400 : '100%',
                      borderRadius: 6,
                      padding: 14,
                    }}
                    textStyle={{
                      color: COLORS.GREY,
                      fontSize: 16,
                      fontFamily: FONTS.AvenirMedium,
                      letterSpacing: 1,
                    }}
                    dropDownContainerTextStyle={{
                      color: COLORS.DARKER_GREY,
                    }}
                    buttonStyle={{
                      tintColor: COLORS.DARKER_GREY,
                    }}
                    setValues={(value) => {
                      field.onChange(field.name)(value.value.toString());
                    }}
                    title={component.titleDropdown}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        case 'mobilePhone':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={[!isDesktop() && { width: '100%' }]}>
                  <MobileInput
                    setValues={(value) => {
                      field.onChange(field.name)(value);
                    }}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        case 'radio':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View>
                  <RadioButton
                    options={component.items ? component.items : []}
                    selectedOption={field.value}
                    onSelect={(value) => {
                      field.onChange(field.name)(value.toString());
                    }}
                    radioStyle={{ justifyContent: 'space-around' }}
                  />
                </View>
              )}
            </Field>
          );
        default:
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View
                  style={{
                    width: '100%',
                  }}
                >
                  <TextInput
                    style={[
                      (isDesktop()
                        ? STYLE_VARS.textinputweb
                        : STYLE_VARS.textinput) as TextStyle,
                      authStyles.inputWithNote,
                    ]}
                    value={field.value}
                    onChangeText={(text) => {
                      field.onChange(field.name)(text);
                    }}
                    placeholder={component.placeholder}
                    placeholderTextColor={COLORS.GREY}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
      }
    },
    [isDesktop]
  );

  useEffect(() => {
    getUserType();
  }, [getUserType]);

  return (
    <View
      style={[
        {
          paddingTop: isDesktop() ? 40 : 0,
          minHeight: 700,
        },
        isDesktop()
          ? {
              maxWidth: MAX_WIDTH,
              width: '100%',
              alignSelf: 'center',
              paddingLeft: 20,
              paddingRight: 20,
              marginBottom: 35,
            }
          : {
              paddingHorizontal: 25,
            },
      ]}
    >
      <View style={authStyles.container}>
        <Text
          style={[
            authStyles.title,
            isDesktop()
              ? {
                  textAlign: 'left',
                }
              : {
                  textAlign: 'center',
                  fontSize: 20,
                },
          ]}
        >
          CREATE AN ACCOUNT
        </Text>
        <Formik
          initialValues={initData}
          onSubmit={(value) => {
            if (onSubmitHandler) onSubmitHandler(value);
          }}
          validationSchema={yup.object().shape({
            first_name: yup.string().required('Please select a first name.'),
            last_name: yup.string().required('Please enter a last name.'),
            user_type_id: yup
              .string()
              .required('Please select a account category.'),
            email: yup
              .string()
              .required('Please enter a email.')
              .email('Wrong email format!'),
            phone: yup.string().required('Please enter a mobile number.'),
            birthday: yup.string().required('Please enter a date of birth.'),
            card_id: yup.string().required('Please enter a HKID card number.'),
            terms: yup.string().required('Please agree the policy'),
          })}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <>
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginBottom: 30,
                  zIndex: 1,
                }}
              >
                {createAccountStructure.components.map((component) => (
                  <View
                    style={[
                      {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginBottom: 20,
                        zIndex: component.zIndex ? component.zIndex : 0,
                      },
                      isDesktop()
                        ? {
                            width: '50%',
                          }
                        : {
                            width: '100%',
                          },
                    ]}
                  >
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: 19,
                        color: COLORS.BLACK,
                        marginBottom: 20,
                      }}
                    >
                      {component.label}
                      {component.isRequire && (
                        <Text
                          style={{
                            color: COLORS.RED_PRIMARY,
                          }}
                        >
                          *
                        </Text>
                      )}
                    </Text>
                    {renderForm(component)}
                  </View>
                ))}
              </View>
              <View
                style={{
                  marginBottom: 50,
                }}
              >
                <View
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <Field name={'terms'}>
                    {({ field }: FieldProps) => (
                      <View
                        style={{
                          width: '100%',
                        }}
                      >
                        <Checkbox
                          label={'I agree to the Helping Hand Privacy Policy'}
                          isChecked={isAgree}
                          onChange={() => {
                            setIsAgree(!isAgree);
                            field.onChange(field.name)(isAgree ? '' : '1');
                          }}
                        />
                        <ErrorMessage
                          name={field.name}
                          render={(errorMessage: string) => (
                            <Text style={{ color: 'red', marginTop: 5 }}>
                              {errorMessage}
                            </Text>
                          )}
                        />
                      </View>
                    )}
                  </Field>
                </View>
                <Button
                  style={styles.buttonStyle}
                  className=""
                  arrowRight={false}
                  textStyle={styles.textButtonStyle}
                  text="Submit"
                  onPress={() => {
                    handleSubmit();
                  }}
                />
              </View>
            </>
          )}
        </Formik>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 15,
    borderRadius: 13,
    width: 300,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});

export default CreateAccountForm;
