import { FC, useCallback, useMemo } from 'react';
import {
  FormComponent,
  FormStructure,
  IFormData,
} from '../../../components/FormHelper/FormHelper.types';
import {
  Image,
  Linking,
  StyleSheet,
  Text,
  TextStyle,
  View,
} from 'react-native';
import { ErrorMessage, Field, FieldProps, Formik } from 'formik';
import { STYLE_VARS } from '../../../constants/style-vars';
import { TextInput } from '../../../components/TextInput/TextInput';
import { Button } from '../../../components/Button';
import DropDown from '../../../components/DropDown/DropDown';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';

type ContactUsFormProps = {
  onSubmitHandler?: (data: IFormData) => void;
};

const ContactUsForm: FC<ContactUsFormProps> = ({ onSubmitHandler }) => {
  const isDesktop = useIsDesktop();
  const initData = useMemo(() => {
    return {
      name: '',
      email: '',
      phoneNumber: '',
      company: '',
      matter: '',
      typeOfEnquiry: '',
      detail: '',
    };
  }, []);

  const contactUsStructure = useMemo(() => {
    const structure: FormStructure = {
      components: [
        {
          name: 'name',
          type: 'text',
          label: 'Name',
          isRequire: true,
        },
        {
          name: 'email',
          type: 'text',
          label: 'Email',
          isRequire: true,
        },
        {
          name: 'phoneNumber',
          type: 'mobilePhone',
          label: 'Phone Number',
        },
        {
          name: 'company',
          type: 'text',
          label: 'Company',
        },
        {
          name: 'matter',
          type: 'dropdown',
          label: 'Matter',
          items: [],
          placeholder: ' ',
          zIndex: 3,
        },
        {
          name: 'typeOfEnquiry',
          type: 'dropdown',
          label: 'Type of enquiry',
          items: [],
          placeholder: ' ',
          zIndex: 2,
        },
      ],
    };
    return structure;
  }, []);

  const renderForm = useCallback(
    (component: FormComponent) => {
      switch (component.type) {
        case 'text':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View
                  style={{
                    width: '100%',
                  }}
                >
                  <TextInput
                    style={{
                      width: '100%',
                      borderWidth: 1,
                      borderColor: '#DDDDE3',
                      borderStyle: 'solid',
                      padding: 10,
                      letterSpacing: 1.2,
                      borderRadius: 6,
                      fontSize: 16,
                      backgroundColor: 'white',
                      fontFamily: FONTS.AvenirMedium,
                    }}
                    value={field.value}
                    onChangeText={(text) => {
                      field.onChange(field.name)(text);
                    }}
                    placeholder={component.placeholder}
                    placeholderTextColor={COLORS.GREY}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        case 'dropdown':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={[{ width: '100%' }]}>
                  <DropDown
                    items={component.items || []}
                    placeholder={component.placeholder}
                    zIndex={component.zIndex}
                    dropdownStyle={{
                      borderColor: '#DDDDE3',
                      width: isDesktop() ? '100%' : '100%',
                      borderRadius: 6,
                      padding: 12,
                    }}
                    textStyle={{
                      color: COLORS.GREY,
                      fontSize: 16,
                      fontFamily: FONTS.AvenirMedium,
                      letterSpacing: 1,
                    }}
                    dropDownContainerTextStyle={{
                      color: COLORS.DARKER_GREY,
                    }}
                    buttonStyle={{
                      tintColor: COLORS.DARKER_GREY,
                    }}
                    setValues={(value) => {
                      field.onChange(field.name)(value.value.toString());
                    }}
                    title={component.titleDropdown}
                    customDownButton={require('../../../assets/down-arrow.png')}
                    customUpButton={require('../../../assets/up-arrow.png')}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        case 'mobilePhone':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={[{ width: '100%' }]}>
                  <TextInput
                    style={{
                      width: '100%',
                      borderWidth: 1,
                      borderColor: '#DDDDE3',
                      borderStyle: 'solid',
                      padding: 10,
                      letterSpacing: 1.2,
                      borderRadius: 6,
                      fontSize: 16,
                      backgroundColor: 'white',
                      fontFamily: FONTS.AvenirMedium,
                    }}
                    value={field.value}
                    onChangeText={(text) => {
                      field.onChange(field.name)(text);
                    }}
                    placeholder={component.placeholder}
                    placeholderTextColor={COLORS.GREY}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        default:
          return <></>;
      }
    },
    [isDesktop]
  );

  return (
    <View>
      <Formik
        initialValues={initData}
        onSubmit={(value) => {
          if (onSubmitHandler) onSubmitHandler(value);
        }}
      >
        {({ handleSubmit, handleChange, values }) => (
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginBottom: 30,
              zIndex: 1,
              justifyContent: 'space-between',
            }}
          >
            {contactUsStructure.components.map((component) => (
              <View
                style={[
                  {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginBottom: 20,
                    zIndex: component.zIndex ? component.zIndex : 0,
                  },
                  isDesktop()
                    ? {
                        width: '47%',
                      }
                    : {
                        width: '100%',
                      },
                ]}
              >
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 16,
                    color: COLORS.GREY,
                    marginBottom: 10,
                  }}
                >
                  {component.label}
                  {component.isRequire && (
                    <Text
                      style={{
                        color: COLORS.RED_PRIMARY,
                      }}
                    >
                      *
                    </Text>
                  )}
                </Text>
                {renderForm(component)}
              </View>
            ))}
            <View
              style={[
                {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginBottom: 20,
                  width: '100%',
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 16,
                  color: COLORS.GREY,
                  marginBottom: 10,
                }}
              >
                {'Detail'}
              </Text>
              <TextInput
                placeholder={''}
                onChangeText={handleChange('detail')}
                value={values.detail}
                textAlignVertical="top"
                multiline
                placeholderTextColor={STYLE_VARS.fccDarkerGrey}
                style={styles.textarea}
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                style={{
                  backgroundColor: COLORS.RED_PRIMARY,
                  paddingVertical: 12,
                  borderRadius: 13,
                  width: 280,
                  marginBottom: 20,
                  overflow: 'hidden',
                }}
                className=""
                textStyle={{
                  color: COLORS.WHITE,
                  fontSize: 19,
                  fontWeight: '800',
                  fontFamily: FONTS.AvenirMedium,
                  textTransform: 'uppercase',
                }}
                text="SUBMIT"
                arrowRight={false}
              />
            </View>
          </View>
        )}
      </Formik>
      <View
        style={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <Image
            source={require('../../../assets/map.png')}
            style={{ width: 24, height: 24, marginRight: 6 }}
          />
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '700',
              fontSize: 20,
              color: COLORS.BLACK,
              textTransform: 'uppercase',
              letterSpacing: 1.5,
            }}
          >
            MAP LOCATION
          </Text>
        </View>
        {isDesktop() ? (
          <View
            style={{
              marginBottom: 30,
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <iframe
              style={{
                height: isDesktop() ? 500 : 350,
                width: isDesktop() ? 500 : '100%',
              }}
              frameBorder="0"
              src="https://www.google.com/maps/embed/v1/place?q=Helping+Hand+cheung+muk+&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            ></iframe>
          </View>
        ) : (
          <></>
        )}
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          style={{
            backgroundColor: COLORS.RED_PRIMARY,
            paddingVertical: 12,
            borderRadius: 13,
            width: 280,
            marginBottom: 40,
            overflow: 'hidden',
          }}
          className=""
          textStyle={{
            color: COLORS.WHITE,
            fontSize: 19,
            fontWeight: '800',
            fontFamily: FONTS.AvenirMedium,
            textTransform: 'uppercase',
          }}
          text="GET DIRECTIONS"
          arrowRight={false}
          onPress={() => {
            Linking.openURL('https://maps.app.goo.gl/tDpPPXafJeQFyNxZ9');
          }}
        />
      </View>
    </View>
  );
};

export default ContactUsForm;

const styles = StyleSheet.create({
  input: {
    paddingHorizontal: 10,
    paddingVertical: 7,
    borderWidth: 1,
    borderColor: STYLE_VARS.fccDarkGrey,
    fontFamily: FONTS.AvenirMedium,
    fontSize: 12,
    fontWeight: '500',
    color: STYLE_VARS.fccDarkerGrey,
  },
  textarea: {
    height: 150,
    backgroundColor: 'white',
    paddingHorizontal: 10,
    alignItems: 'flex-start',
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: COLORS.LGREY,
    color: COLORS.DARKER_GREY,
    fontSize: 12,
    fontWeight: '500',
    fontFamily: FONTS.AvenirMedium,
    marginBottom: 30,
    width: '100%',
    borderRadius: 6,
  },
});
