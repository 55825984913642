import { FC, memo, useMemo, useRef, useState } from 'react';
import {
  Animated,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../constants';
import { MAX_WIDTH } from '../constants/platform';
import { NavigationService } from '../services/navigation';
import { addBase64Prefix } from '../services/addBase64Prefix';
import { logo_hh } from '../assets/static/base64-new';
import { useSelector } from 'react-redux';
import { RootState } from '../services/redux';

const MenuItem: FC<{
  title: string;
  contents?: {
    title: string;
    onPress?: () => void;
  }[];
  active?: boolean;
  hasDropDown?: boolean;
  onPress?: () => void;
}> = memo(
  ({ title, hasDropDown = true, onPress, contents, active = false }) => {
    const [isHover, setHover] = useState<boolean>(false);
    const [contentHover, setContentHover] = useState<any>({});
    const progress = useRef(new Animated.Value(0)).current;
    const imageAnimate = useRef(new Animated.Value(0)).current;
    const { width } = useWindowDimensions();
    const handleMouseEnter = () => {
      setHover(true);
      Animated.timing(progress, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
      }).start();
    };

    const handleMouseLeave = () => {
      setHover(false);
      Animated.timing(progress, {
        toValue: 0,
        duration: 0,
        useNativeDriver: false,
      }).start();
    };

    const handleMouseContentEnter = (content: any) => {
      setContentHover(content);
      Animated.timing(imageAnimate, {
        toValue: 1,
        duration: 400,
        useNativeDriver: false,
      }).start();
    };

    const handleMouseContentLeave = () => {
      setContentHover({});
      Animated.timing(imageAnimate, {
        toValue: 0,
        duration: 0,
        useNativeDriver: false,
      }).start();
    };
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={(e) => {
          if (onPress) onPress();
        }}
      >
        <View
          style={{
            alignItems: 'center',
            marginRight: 18,
            marginLeft: 18,
            marginBottom: 10,
          }}
        >
          <Text style={styles.headerTabText}>{title}</Text>
          {(isHover || active) && (
            <View
              style={{
                position: 'absolute',
                left: -18,
                right: -18,
                bottom: -14,
                height: 3,
                backgroundColor: COLORS.RED_PRIMARY,
              }}
            />
          )}
        </View>
        <View
          style={{
            position: 'relative',
          }}
        >
          {hasDropDown && isHover && (
            <Animated.View
              style={{
                opacity: progress,
                position: 'absolute',
                width: 350,
                left: 0,
                borderBottomLeftRadius: 18,
                borderBottomRightRadius: 18,
                top: 4,
                backgroundColor: '#ffffff',
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.5,
                shadowRadius: 3.84,
                elevation: 5,
              }}
            >
              <View
                style={{
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  paddingVertical: 6,
                }}
              >
                {contents?.map((content) => (
                  <div
                    style={{ alignItems: 'flex-start', width: '100%' }}
                    onMouseEnter={() => {
                      handleMouseContentEnter(content);
                    }}
                    onMouseLeave={handleMouseContentLeave}
                    key={content.title}
                  >
                    <TouchableOpacity
                      key={content.title}
                      style={{
                        paddingHorizontal: 18,
                        paddingVertical: 3,
                      }}
                      onPress={() => {
                        if (content.onPress) {
                          content.onPress();
                          handleMouseLeave();
                          setHover(false);
                        }
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: FONTS.AvenirMedium,
                          color:
                            content == contentHover ? COLORS.RED : COLORS.BLACK,
                          fontWeight: content == contentHover ? '500' : '400',
                          fontSize: 18,
                        }}
                      >
                        {content.title}
                      </Text>
                    </TouchableOpacity>
                  </div>
                ))}
              </View>
            </Animated.View>
          )}
        </View>
      </div>
    );
  }
);

const NavHeaderDesktop: FC = () => {
  const { width } = useWindowDimensions();
  const curRoute = useSelector((redux: RootState) => redux.route);
  const auth = useSelector((redux: RootState) => redux.authReducer);

  const margin = useMemo(
    () => Math.max(Math.min(width - 1150, 120), -70),
    [width]
  );
  const HEADER_MENU = useMemo(() => {
    return [
      {
        title: 'Home',
        active:
          curRoute.name == 'Root' &&
          curRoute.params?.params?.screen == 'HomePage'
            ? true
            : false,
        hasDropDown: false,
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'HomePage',
            },
          });
        },
      },
      {
        title: 'Make A Booking',
        active:
          curRoute.name == 'Root' && curRoute.params?.screen == 'BookingList'
            ? true
            : false,
        hasDropDown: false,
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'BookingList',
          });
        },
      },
      {
        title: 'Camps',
        active:
          curRoute.name == 'Root' &&
          curRoute.params?.params?.screen == 'BookingDetail'
            ? true
            : false,
        contents: [
          {
            title: 'Day Camp',
            onPress: () => {},
          },
          {
            title: 'Evening Camp',
            onPress: () => {},
          },
          {
            title: 'Overnight Camp',
            onPress: () => {
              NavigationService.push('Root', {
                screen: 'MenuTab',
                params: {
                  screen: 'BookingDetail',
                },
              });
            },
          },
          {
            title: 'Morning Half Day Camp',
            onPress: () => {},
          },
          {
            title: 'Evening Half Day Camp',
            onPress: () => {},
          },
          {
            title: 'Morning Half Day Camp + Lunch',
            onPress: () => {},
          },
          {
            title: 'Evening Half Day Camp + Lunch',
            onPress: () => {},
          },
        ],
        onPress: () => {},
      },
      {
        title: 'Activities',
        active:
          curRoute.name == 'Root' && curRoute.params?.screen == 'MyActivities'
            ? true
            : false,
        hasDropDown: false,
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MyActivities',
          });
        },
      },
      {
        title: 'My Account',
        active:
          curRoute.name == 'Root' &&
          curRoute.params?.params?.screen == 'MyProfile'
            ? true
            : false,
        hasDropDown: false,
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyProfile',
            },
          });
        },
      },
      {
        title: 'Contact Us',
        active:
          curRoute.name == 'Root' &&
          curRoute.params?.params?.screen == 'ContactUs'
            ? true
            : false,
        hasDropDown: false,
        onPress: () => {
          NavigationService.push<'Root'>('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'ContactUs',
            },
          });
        },
      },
    ];
  }, [curRoute]);
  return (
    <View
      style={{
        flexDirection: 'column',
        zIndex: 10,
        shadowColor: '#F0F0F0',
        shadowOffset: { width: 0, height: 12 },
        shadowOpacity: 1,
        shadowRadius: 32,
      }}
    >
      <View style={{ backgroundColor: COLORS.WHITE }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            maxWidth: MAX_WIDTH,
            width: '100%',
            alignSelf: 'center',
            padding: '4px 0',
          }}
        >
          <TouchableOpacity
            onPress={() => {
              NavigationService.push('Root', {
                screen: 'MenuTab',
                params: {
                  screen: 'HomePage',
                },
              });
            }}
          >
            <Image
              source={{ uri: addBase64Prefix(logo_hh) }}
              style={{
                width: 100,
                height: 100,
                marginLeft: 20,
                resizeMode: 'contain',
              }}
            />
          </TouchableOpacity>

          {auth.loggedin == true && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: margin,
                alignItems: 'flex-end',
              }}
            >
              {HEADER_MENU.map((item, index) => (
                <MenuItem {...item} key={index} />
              ))}
            </div>
          )}
        </div>
      </View>
    </View>
  );
};

export default NavHeaderDesktop;

const styles = StyleSheet.create({
  headerTabText: {
    color: COLORS.BLACK,
    fontSize: 18,
    lineHeight: 16,
    letterSpacing: 0.5,
  },
});
