import { FC, useCallback, useMemo } from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { STYLE_VARS } from '../../constants/style-vars';
import { Button } from '../../components/Button';
import {
  FormComponent,
  FormStructure,
} from '../../components/FormHelper/FormHelper.types';
import { ErrorMessage, Field, FieldProps, Formik } from 'formik';
import InputDatePicker from '../../components/InputDatePicker/InputDatePicker';
import DropDown from '../../components/DropDown';
import RadioButton from '../../components/RadioButton/RadioButton';
import { authStyles } from '../Auth/styles';
import * as yup from 'yup';
import { MAX_WIDTH } from '../../constants/platform';
import { NavigationService } from '../../services/navigation';
import { EditProfileProps } from './types';
import MyDetailForm from './components/MyDetailForm';
import { Footer } from '../../components/Footer';
import MyProfileForm from './components/MyProfileForm';

const EditProfile: FC<EditProfileProps> = ({ navigation, route }) => {
  const name = route.params.name;
  const isDesktop = useIsDesktop();
  const initData = useMemo(() => {
    return {
      email: '',
      phone: '',
      mobilePhone: '',
      cardNo: '',
      birthday: '',
      disabled: '',
      address: '',
      unitNo: '',
      floor: '',
      buildingNameAndNumber: '',
      streetNameAndNumber: '',
      district: '',
      region: '',
      emergencyFirstName: '',
      emergencyLastName: '',
      emergencyPhone: '',
      emergencyEmail: '',
    };
  }, []);

  const editProfileStructure = useMemo(() => {
    const structure: FormStructure = {
      components: [
        {
          label: 'Email',
          name: 'email',
          type: 'text',
          placeholder: 'Enter Email',
          isRequire: true,
        },
        {
          label: 'Phone',
          name: 'phone',
          type: 'mobilePhone',
          placeholder: 'Phone Number',
        },
        {
          label: 'Mobile Phone',
          name: 'mobilePhone',
          type: 'mobilePhone',
          placeholder: 'Phone Number',
          isRequire: true,
          zIndex: 2,
        },
        {
          label: 'HKID Card No',
          name: 'cardNo',
          type: 'text',
          placeholder: '',
          isRequire: true,
          disabled: true,
        },
        {
          label: 'Date of Birth',
          name: 'birthday',
          type: 'datePicker',
          placeholder: 'DD/MM/YYYY',
          isRequire: true,
          zIndex: 1,
        },
        {
          label: 'Disabled',
          name: 'disabled',
          type: 'radio',
          items: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ],
        },
        {
          label: 'Address',
          name: 'address',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Unit No',
          name: 'unitNo',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Floor',
          name: 'floor',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Building Name and Number',
          name: 'buildingNameAndNumber',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Street Name and Number',
          name: 'streetNameAndNumber',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'District',
          name: 'district',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Region',
          name: 'region',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Emergency Contact (First Name)',
          name: 'emergencyFirstName',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Emergency Contact (Last Name)',
          name: 'emergencyLastName',
          type: 'text',
          isRequire: true,
        },
        {
          label: 'Emergency Contact (Phone)',
          name: 'emergencyPhone',
          type: 'mobilePhone',
          placeholder: 'Phone Number',
          isRequire: true,
        },
        {
          label: 'Emergency Contact (Email)',
          name: 'emergencyEmail',
          type: 'text',
          isRequire: true,
        },
      ],
    };

    return structure;
  }, []);

  const renderForm = useCallback(
    (component: FormComponent) => {
      switch (component.type) {
        case 'datePicker':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={[{ zIndex: 1, width: '100%' }]}>
                  <InputDatePicker
                    date={field.value}
                    onChangeDate={(date) => {
                      field.onChange(field.name)(date);
                    }}
                    placeholder={component.placeholder}
                    inputStyle={{
                      width: '100%',
                      backgroundColor: COLORS.WHITE,
                    }}
                  />
                </View>
              )}
            </Field>
          );
        case 'dropdown':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={[!isDesktop() && { width: '100%' }]}>
                  <DropDown
                    items={component.items || []}
                    placeholder={component.placeholder}
                    zIndex={component.zIndex}
                    dropdownStyle={{
                      borderColor: '#DDDDE3',
                      width: isDesktop() ? 400 : '100%',
                      borderRadius: 6,
                      padding: 14,
                    }}
                    textStyle={{
                      color: COLORS.GREY,
                      fontSize: 16,
                      fontFamily: FONTS.AvenirMedium,
                      letterSpacing: 1,
                    }}
                    dropDownContainerTextStyle={{
                      color: COLORS.DARKER_GREY,
                    }}
                    buttonStyle={{
                      tintColor: COLORS.DARKER_GREY,
                    }}
                    setValues={(value) => {
                      field.onChange(field.name)(value.value.toString());
                    }}
                    title={component.titleDropdown}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        case 'mobilePhone':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View style={{ width: '100%' }}>
                  <View style={{ flexDirection: 'row' }}>
                    <DropDown
                      items={component.items || []}
                      placeholder={'+'}
                      customDownButton={require('../../assets/down-arrow.png')}
                      customUpButton={require('../../assets/up-arrow.png')}
                      zIndex={component.zIndex}
                      dropdownStyle={{
                        borderColor: '#DDDDE3',
                        width: 95,
                        borderRadius: 6,
                        padding: 12,
                        marginRight: 5,
                      }}
                      textStyle={{
                        color: COLORS.GREY,
                        fontSize: 16,
                        fontFamily: FONTS.AvenirMedium,
                        letterSpacing: 1,
                      }}
                      dropDownContainerTextStyle={{
                        color: COLORS.DARKER_GREY,
                      }}
                      buttonStyle={{
                        tintColor: COLORS.DARKER_GREY,
                      }}
                      setValues={(value) => {
                        //field.onChange(field.name)(value.value.toString());
                      }}
                      title={component.titleDropdown}
                    />
                    <TextInput
                      style={{
                        flex: 1,
                        borderWidth: 1,
                        borderColor: '#DDDDE3',
                        borderStyle: 'solid',
                        padding: 12,
                        letterSpacing: 1.2,
                        borderRadius: 6,
                        fontSize: 16,
                        backgroundColor: 'white',
                        fontFamily: FONTS.AvenirMedium,
                      }}
                      placeholder={component.placeholder}
                      placeholderTextColor={COLORS.GREY}
                      value={field.value}
                      onChangeText={(text) => {
                        field.onChange(field.name)(text);
                      }}
                    />
                  </View>
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
        case 'radio':
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View>
                  <RadioButton
                    options={component.items ? component.items : []}
                    selectedOption={field.value}
                    onSelect={(value) => {
                      field.onChange(field.name)(value.toString());
                    }}
                    radioStyle={{ justifyContent: 'space-around' }}
                  />
                </View>
              )}
            </Field>
          );
        default:
          return (
            <Field name={component.name}>
              {({ field }: FieldProps) => (
                <View
                  style={{
                    width: '100%',
                  }}
                >
                  <TextInput
                    style={{
                      width: '100%',
                      borderWidth: 1,
                      borderColor: '#DDDDE3',
                      borderStyle: 'solid',
                      padding: 12,
                      letterSpacing: 1.2,
                      borderRadius: 6,
                      fontSize: 16,
                      backgroundColor: component.disabled
                        ? COLORS.LIGHT_GREY
                        : 'white',
                      fontFamily: FONTS.AvenirMedium,
                    }}
                    value={field.value}
                    onChangeText={(text) => {
                      field.onChange(field.name)(text);
                    }}
                    placeholder={component.placeholder}
                    placeholderTextColor={COLORS.GREY}
                    editable={!component.disabled}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          );
      }
    },
    [isDesktop]
  );

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="Edit My Profile" />
              <View
                style={{
                  paddingHorizontal: 25,
                  paddingTop: 25,
                  backgroundColor: COLORS.WHITE,
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 0.5,
                  shadowRadius: 32,
                  elevation: 24,
                  borderRadius: 18,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {name === 'detail' && <MyDetailForm />}
                {name === 'profile' && <MyProfileForm />}
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'EDIT PROFILE'} />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: COLORS.LIGHT_RED,
        }}
      >
        <View
          style={{
            paddingBottom: 20,
            borderBottomWidth: 1,
            borderBottomColor: COLORS.GREY,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '700',
              fontSize: 20,
              color: COLORS.BLACK,
              textTransform: 'uppercase',
            }}
          >
            LING CHENG
          </Text>
        </View>
        {name === 'detail' && <MyDetailForm />}
        <View style={{ marginBottom: 100 }}>
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '700',
              fontSize: 18,
              letterSpacing: 1.5,
              marginBottom: 15,
            }}
          >
            MY PROFILE
          </Text>
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                First Name:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>Ling</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Last Name:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>Cheng</Text>
            </View>
          </View>
          <Formik
            initialValues={initData}
            onSubmit={(value) => {}}
            validationSchema={yup.object().shape({})}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    zIndex: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  {editProfileStructure.components.map((component) => (
                    <View
                      style={[
                        {
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          marginBottom: 15,
                          zIndex: component.zIndex ? component.zIndex : 0,
                        },
                        isDesktop()
                          ? {
                              width: '48%',
                            }
                          : {
                              width: '100%',
                            },
                      ]}
                    >
                      <Text
                        style={{
                          fontFamily: FONTS.AvenirMedium,
                          fontSize: 16,
                          color: COLORS.GREY,
                          marginBottom: 15,
                        }}
                      >
                        {component.label}
                        {component.isRequire && (
                          <Text
                            style={{
                              color: COLORS.RED_PRIMARY,
                            }}
                          >
                            *
                          </Text>
                        )}
                        :
                      </Text>
                      {renderForm(component)}
                    </View>
                  ))}
                </View>
                <Button
                  style={{
                    backgroundColor: COLORS.RED_PRIMARY,
                    paddingVertical: 15,
                    borderRadius: 16,
                    width: '100%',
                    overflow: 'hidden',
                  }}
                  className=""
                  textStyle={{
                    color: COLORS.WHITE,
                    fontSize: 19,
                    fontWeight: '800',
                    fontFamily: FONTS.AvenirMedium,
                    textTransform: 'uppercase',
                  }}
                  text="Save"
                  arrowRight={false}
                  onPress={() => {
                    NavigationService.push('Root', {
                      screen: 'MenuTab',
                      params: {
                        screen: 'MyProfile',
                      },
                    });
                  }}
                />
              </>
            )}
          </Formik>
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default EditProfile;

const styles = StyleSheet.create({
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
});
