import { FC, memo, useMemo, useRef, useState } from 'react';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import {
  Animated,
  Dimensions,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { Text } from 'react-native';
import { Calendar } from '../../../components/Calendar';
import GuestPicker from '../../../components/GuestPicker/GuestPicker';
import dayjs from 'dayjs';
import { NavigationService } from '../../../services/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/redux';

const BookingTimePicker: FC<{
  checkInDay: string;
  setCheckInDay: (date: string) => void;
  checkOutDay: string;
  setCheckOutDay: (date: string) => void;
}> = ({ checkInDay, setCheckInDay, checkOutDay, setCheckOutDay }) => {
  const isDesktop = useIsDesktop();
  const [showCalendarCheckout, setShowCalendarCheckout] =
    useState<boolean>(false);
  const [showCalendarCheckIn, setShowCalendarCheckIn] =
    useState<boolean>(false);
  const [isHoverCheckIn, setIsHoverCheckIn] = useState<boolean>(false);
  const [isHoverCheckOut, setIsHoverCheckOut] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;

  const handleMouseCheckInEnter = () => {
    setIsHoverCheckIn(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseCheckInLeave = () => {
    setIsHoverCheckIn(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseCheckOutEnter = () => {
    setIsHoverCheckOut(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseCheckOutLeave = () => {
    setIsHoverCheckOut(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  return (
    <View
      style={[
        {
          padding: 5,
          borderWidth: 1,
          borderColor: COLORS.GREY,
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 16,
          marginBottom: 10,
          backgroundColor: COLORS.WHITE,
          marginEnd: isDesktop() ? 10 : 0,
          zIndex: 1,
        },
        !isDesktop() && {
          width: '100%',
        },
      ]}
    >
      {isDesktop() ? (
        <div
          onMouseEnter={handleMouseCheckInEnter}
          onMouseLeave={handleMouseCheckInLeave}
          style={{ width: isDesktop() ? 170 : '49%' }}
        >
          <TouchableOpacity
            style={[
              styles.pickCalendarWrapper,
              isDesktop()
                ? {
                    paddingVertical: 0,
                  }
                : {},
            ]}
            onPress={() => {
              setShowCalendarCheckIn(!showCalendarCheckIn);
            }}
          >
            <Image
              source={require('../../../assets/check-in-icon.png')}
              style={styles.pickCalendarIcon}
            />
            <View>
              <Text style={[styles.dateText, !isDesktop() && { fontSize: 14 }]}>
                {dayjs(new Date(checkInDay)).format('ddd, MMM DD')}
              </Text>
              <Text
                style={{
                  fontSize: isDesktop() ? 16 : 14,
                  color: isHoverCheckIn ? COLORS.RED_PRIMARY : COLORS.GREY,
                  fontFamily: FONTS.AvenirMedium,
                }}
              >
                Check in
              </Text>
            </View>
            {showCalendarCheckIn && (
              <View
                style={[
                  {
                    zIndex: 100000,
                    width: 300,
                    position: 'absolute',
                    backgroundColor: COLORS.WHITE,

                    top: 70,
                    shadowColor: '#000000',
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowOpacity: 0.5,
                    shadowRadius: 3,
                    elevation: 3,
                    padding: 20,
                    borderRadius: 8,
                  },
                  isDesktop()
                    ? {
                        right: 0,
                      }
                    : {
                        left: 0,
                      },
                ]}
              >
                <Calendar
                  initDate={checkInDay}
                  onChangeDate={(date) => {
                    setCheckInDay(date);
                    setIsHoverCheckIn(false);
                  }}
                  setShowCalendar={setShowCalendarCheckIn}
                  showCalendar={showCalendarCheckIn}
                />
              </View>
            )}
          </TouchableOpacity>
        </div>
      ) : (
        <View style={{ width: isDesktop() ? 170 : '49%' }}>
          <TouchableOpacity
            style={[
              styles.pickCalendarWrapper,
              isDesktop()
                ? {
                    paddingVertical: 0,
                  }
                : {},
            ]}
            onPress={() => {
              setShowCalendarCheckIn(!showCalendarCheckIn);
            }}
          >
            <Image
              source={require('../../../assets/check-in-icon.png')}
              style={styles.pickCalendarIcon}
            />
            <View>
              <Text style={[styles.dateText, !isDesktop() && { fontSize: 14 }]}>
                {dayjs(new Date(checkInDay)).format('ddd, MMM DD')}
              </Text>
              <Text
                style={{
                  fontSize: isDesktop() ? 16 : 14,
                  color: isHoverCheckIn ? COLORS.RED_PRIMARY : COLORS.GREY,
                  fontFamily: FONTS.AvenirMedium,
                }}
              >
                Check in
              </Text>
            </View>
            {showCalendarCheckIn && (
              <View
                style={[
                  {
                    zIndex: 100000,
                    width: 300,
                    position: 'absolute',
                    backgroundColor: COLORS.WHITE,

                    top: 70,
                    shadowColor: '#000000',
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowOpacity: 0.5,
                    shadowRadius: 3,
                    elevation: 3,
                    padding: 20,
                    borderRadius: 8,
                  },
                  isDesktop()
                    ? {
                        right: 0,
                      }
                    : {
                        left: 0,
                      },
                ]}
              >
                <Calendar
                  initDate={checkInDay}
                  onChangeDate={(date) => {
                    setCheckInDay(date);
                    setIsHoverCheckIn(false);
                  }}
                  setShowCalendar={setShowCalendarCheckIn}
                  showCalendar={showCalendarCheckIn}
                />
              </View>
            )}
          </TouchableOpacity>
        </View>
      )}

      <View
        style={{
          width: 1,
          height: 35,
          backgroundColor: COLORS.BLACK,
        }}
      ></View>
      {isDesktop() ? (
        <div
          onMouseEnter={handleMouseCheckOutEnter}
          onMouseLeave={handleMouseCheckOutLeave}
          style={{ width: isDesktop() ? 170 : '49%' }}
        >
          <TouchableOpacity
            style={[
              styles.pickCalendarWrapper,
              isDesktop()
                ? {
                    paddingVertical: 0,
                  }
                : {},
            ]}
            onPress={() => {
              setShowCalendarCheckout(!showCalendarCheckout);
            }}
          >
            <Image
              source={require('../../../assets/check-out-icon.png')}
              style={styles.pickCalendarIcon}
            />
            <View>
              <Text style={[styles.dateText, !isDesktop() && { fontSize: 14 }]}>
                {dayjs(new Date(checkOutDay)).format('ddd, MMM DD')}
              </Text>
              <Text
                style={{
                  fontSize: isDesktop() ? 16 : 14,
                  color: isHoverCheckOut ? COLORS.RED_PRIMARY : COLORS.GREY,
                  fontFamily: FONTS.AvenirMedium,
                }}
              >
                Check out
              </Text>
            </View>
            {showCalendarCheckout && (
              <View
                style={[
                  {
                    zIndex: 100000,
                    width: 300,
                    position: 'absolute',
                    backgroundColor: COLORS.WHITE,
                    top: 70,
                    shadowColor: '#000000',
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowOpacity: 0.5,
                    shadowRadius: 3,
                    elevation: 3,
                    padding: 20,
                    borderRadius: 8,
                  },
                  isDesktop()
                    ? {
                        left: 0,
                      }
                    : {
                        right: 0,
                      },
                ]}
              >
                <Calendar
                  initDate={checkOutDay}
                  onChangeDate={(date) => {
                    setCheckOutDay(date);
                    setIsHoverCheckOut(false);
                  }}
                  setShowCalendar={setShowCalendarCheckout}
                  showCalendar={showCalendarCheckout}
                />
              </View>
            )}
          </TouchableOpacity>
        </div>
      ) : (
        <View style={{ width: isDesktop() ? 170 : '49%' }}>
          <TouchableOpacity
            style={[
              styles.pickCalendarWrapper,
              isDesktop()
                ? {
                    paddingVertical: 0,
                  }
                : {},
            ]}
            onPress={() => {
              setShowCalendarCheckout(!showCalendarCheckout);
            }}
          >
            <Image
              source={require('../../../assets/check-out-icon.png')}
              style={styles.pickCalendarIcon}
            />
            <View>
              <Text style={[styles.dateText, !isDesktop() && { fontSize: 14 }]}>
                {dayjs(new Date(checkOutDay)).format('ddd, MMM DD')}
              </Text>
              <Text
                style={{
                  fontSize: isDesktop() ? 16 : 14,
                  color: isHoverCheckOut ? COLORS.RED_PRIMARY : COLORS.GREY,
                  fontFamily: FONTS.AvenirMedium,
                }}
              >
                Check out
              </Text>
            </View>
            {showCalendarCheckout && (
              <View
                style={[
                  {
                    zIndex: 100000,
                    width: 300,
                    position: 'absolute',
                    backgroundColor: COLORS.WHITE,
                    top: 70,
                    shadowColor: '#000000',
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowOpacity: 0.5,
                    shadowRadius: 3,
                    elevation: 3,
                    padding: 20,
                    borderRadius: 8,
                  },
                  isDesktop()
                    ? {
                        left: 0,
                      }
                    : {
                        right: 0,
                      },
                ]}
              >
                <Calendar
                  initDate={checkOutDay}
                  onChangeDate={(date) => {
                    setCheckOutDay(date);
                    setIsHoverCheckOut(false);
                  }}
                  setShowCalendar={setShowCalendarCheckout}
                  showCalendar={showCalendarCheckout}
                />
              </View>
            )}
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const BookingPeoplePicker: FC<{}> = ({}) => {
  const [showGuestPick, setGuestPick] = useState<boolean>(false);
  const isDesktop = useIsDesktop();
  const [isHover, setHover] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;
  const handleMouseEnter = () => {
    setHover(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseLeave = () => {
    setHover(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  if (isDesktop()) {
    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <TouchableOpacity
          style={[
            {
              padding: 15,
              borderWidth: 1,
              borderColor: COLORS.GREY,
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 16,
              backgroundColor: COLORS.WHITE,
              marginBottom: 10,
            },
            !isDesktop() && { width: '100%' },
          ]}
          onPress={() => {
            setGuestPick(!showGuestPick);
          }}
        >
          <Image
            source={require('../../../assets/user-icon.png')}
            style={[
              {
                marginRight: 20,
              },
              isDesktop()
                ? {
                    width: 25,
                    height: 25,
                  }
                : {
                    width: 20,
                    height: 20,
                  },
            ]}
          />
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                fontSize: isDesktop() ? 19 : 16,
                color: COLORS.RED_PRIMARY,
                fontFamily: FONTS.AvenirMedium,
                letterSpacing: 1,
              }}
            >
              2
            </Text>
            <Text
              style={{
                fontSize: isDesktop() ? 19 : 16,
                color: isHover ? COLORS.RED_PRIMARY : COLORS.GREY,
                fontFamily: FONTS.AvenirMedium,
                letterSpacing: 1,
              }}
            >
              {' '}
              Adults
            </Text>
          </View>
          {showGuestPick && (
            <View
              style={{
                position: 'absolute',
                left: 0,
                top: 70,
              }}
            >
              <GuestPicker />
            </View>
          )}
        </TouchableOpacity>
      </div>
    );
  } else {
    return (
      <View>
        <TouchableOpacity
          style={[
            {
              padding: 15,
              borderWidth: 1,
              borderColor: COLORS.GREY,
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 16,
              backgroundColor: COLORS.WHITE,
              marginBottom: 10,
            },
            !isDesktop() && { width: '100%' },
          ]}
          onPress={() => {
            setGuestPick(!showGuestPick);
          }}
        >
          <Image
            source={require('../../../assets/user-icon.png')}
            style={[
              {
                marginRight: 20,
              },
              isDesktop()
                ? {
                    width: 25,
                    height: 25,
                  }
                : {
                    width: 20,
                    height: 20,
                  },
            ]}
          />
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                fontSize: isDesktop() ? 19 : 16,
                color: COLORS.RED_PRIMARY,
                fontFamily: FONTS.AvenirMedium,
                letterSpacing: 1,
              }}
            >
              2
            </Text>
            <Text
              style={{
                fontSize: isDesktop() ? 19 : 16,
                color: isHover ? COLORS.RED_PRIMARY : COLORS.GREY,
                fontFamily: FONTS.AvenirMedium,
                letterSpacing: 1,
              }}
            >
              {' '}
              Adults
            </Text>
          </View>
          {showGuestPick && (
            <View
              style={{
                position: 'absolute',
                left: 0,
                top: 70,
              }}
            >
              <GuestPicker />
            </View>
          )}
        </TouchableOpacity>
      </View>
    );
  }
};

const BookingTypeItem: FC<{
  title: string;
  active?: boolean;
  value: string;
  onPress?: () => void;
}> = memo(({ title, onPress, active = false }) => {
  const isDesktop = useIsDesktop();
  const [isHover, setHover] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;
  const handleMouseEnter = () => {
    setHover(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseLeave = () => {
    setHover(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  if (isDesktop()) {
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ width: !isDesktop() ? '48%' : 'none' }}
      >
        <TouchableOpacity
          style={[
            {
              backgroundColor:
                isHover || active ? COLORS.RED_PRIMARY : COLORS.WHITE,

              borderWidth: 1,
              borderColor: isHover || active ? COLORS.RED_PRIMARY : COLORS.GREY,
              opacity: 100,
            },
            isDesktop()
              ? {
                  marginHorizontal: 5,
                  paddingHorizontal: 22,
                  paddingVertical: 10,
                  borderRadius: 16,
                }
              : {
                  paddingVertical: 5,
                  marginBottom: 10,
                  borderRadius: 12,
                },
          ]}
          onPress={onPress}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 14,
              color: isHover || active ? COLORS.WHITE : COLORS.GREY,
              letterSpacing: 1,
              textAlign: 'center',
            }}
          >
            {title}
          </Text>
        </TouchableOpacity>
      </div>
    );
  }

  return (
    <View style={{ width: !isDesktop() ? '48%' : 'none' }}>
      <TouchableOpacity
        style={[
          {
            backgroundColor:
              isHover || active ? COLORS.RED_PRIMARY : COLORS.WHITE,

            borderWidth: 1,
            borderColor: isHover || active ? COLORS.RED_PRIMARY : COLORS.GREY,
            opacity: 100,
          },
          isDesktop()
            ? {
                marginHorizontal: 5,
                paddingHorizontal: 22,
                paddingVertical: 10,
                borderRadius: 16,
              }
            : {
                paddingVertical: 5,
                marginBottom: 10,
                borderRadius: 12,
              },
        ]}
        onPress={onPress}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontSize: 14,
            color: isHover || active ? COLORS.WHITE : COLORS.GREY,
            letterSpacing: 1,
            textAlign: 'center',
          }}
        >
          {title}
        </Text>
      </TouchableOpacity>
    </View>
  );
});

export const BookACamp: FC = () => {
  const isDesktop = useIsDesktop();
  const { user, loggedin } = useSelector(
    (state: RootState) => state?.authReducer
  );
  const [typeBook, setTypeBook] = useState<string>('');
  const screenWidth = Dimensions.get('window').width;
  const [checkInDay, setCheckInDay] = useState(
    dayjs(new Date()).format('YYYY-MM-DD')
  );
  const [checkOutDay, setCheckOutDay] = useState(
    dayjs(new Date()).format('YYYY-MM-DD')
  );

  const bookingTypeRender = useMemo(() => {
    const bookingTypeList = [
      {
        text: 'Day Camp',
        value: 'dayCamp',
        onPress: () => {
          setTypeBook('dayCamp');
        },
      },
      {
        text: 'Evening Camp',
        value: 'eveningCamp',
        onPress: () => {
          setTypeBook('eveningCamp');
        },
      },
      {
        text: 'Overnight Camp',
        value: 'overnightCamp',
        onPress: () => {
          setTypeBook('overnightCamp');
        },
      },
      {
        text: 'Half Day Camp',
        value: 'halfDayCamp',
        onPress: () => {
          setTypeBook('halfDayCamp');
        },
      },
    ];

    return (
      <View
        style={[
          {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20,
            flexWrap: 'wrap',
          },
          isDesktop()
            ? {
                justifyContent: 'center',
              }
            : {
                justifyContent: 'space-between',
                paddingHorizontal: 20,
              },
        ]}
      >
        {bookingTypeList.map((bookingTypeItem) => (
          <BookingTypeItem
            title={bookingTypeItem.text}
            active={typeBook === bookingTypeItem.value}
            onPress={bookingTypeItem.onPress}
            value={bookingTypeItem.value}
          />
        ))}
      </View>
    );
  }, [typeBook, isDesktop]);

  //if (isDesktop()) {
  return (
    <View
      style={[
        {
          paddingVertical: 30,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: isDesktop() ? 60 : 0,
          zIndex: 1,
        },
        isDesktop()
          ? {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              shadowColor: '#F0F0F0',
              shadowOffset: { width: 0, height: 12 },
              shadowOpacity: 1,
              shadowRadius: 32,
              borderRadius: 18,
            }
          : {
              backgroundColor: COLORS.LIGHT_GREY,
              borderRadius: 10,
            },
      ]}
    >
      {!isDesktop() && (
        <Text
          style={{
            textTransform: 'uppercase',
            width: '100%',
            paddingHorizontal: 30,
            fontFamily: FONTS.Arial,
            fontWeight: '700',
            fontSize: 18,
            marginBottom: 12,
            letterSpacing: 1,
          }}
        >
          Book a camp
        </Text>
      )}
      {bookingTypeRender}
      <View
        style={[
          {
            zIndex: 1,
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 30,
          },
          !isDesktop() && {
            paddingHorizontal: 20,
            width: '100%',
            flexDirection: 'column',
          },
        ]}
      >
        <BookingTimePicker
          checkInDay={checkInDay}
          checkOutDay={checkOutDay}
          setCheckInDay={setCheckInDay}
          setCheckOutDay={setCheckOutDay}
        />
        <BookingPeoplePicker />
      </View>
      <View
        style={[
          {
            paddingHorizontal: 20,
          },
          !isDesktop() && {
            width: '100%',
          },
        ]}
      >
        <TouchableOpacity
          style={[
            {
              paddingHorizontal: 60,
              paddingVertical: 15,
              backgroundColor: COLORS.RED_PRIMARY,
              borderRadius: 16,
            },
            isDesktop()
              ? {
                  marginBottom: -60,
                }
              : {},
          ]}
          onPress={() => {
            if (loggedin) {
              NavigationService.push('Root', {
                screen: 'MenuTab',
                params: {
                  screen: 'BookingList',
                },
              });
            } else {
              NavigationService.push('Auth');
            }
          }}
        >
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 19,
              fontFamily: FONTS.AvenirMedium,
              color: COLORS.WHITE,
              textAlign: 'center',
            }}
          >
            BOOK NOW
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  pickCalendarWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
    position: 'relative',
    paddingVertical: 10,
  },
  pickCalendarIcon: {
    width: 29,
    height: 22,
    marginRight: 10,
    resizeMode: 'contain',
  },
  dateText: {
    fontSize: 16,
    fontFamily: FONTS.AvenirMedium,
    color: COLORS.RED_PRIMARY,
    textTransform: 'uppercase',
  },
});
