import { FC, useCallback, useState } from 'react';
import { ICreateAccountProps } from '../types';
import {
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TextStyle,
  View,
} from 'react-native';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { MAX_WIDTH } from '../../../constants/platform';
import { authStyles } from '../../Auth/styles';
import { COLORS, FONTS } from '../../../constants';
import { TextInput } from '../../../components/TextInput/TextInput';
import { STYLE_VARS } from '../../../constants/style-vars';
import { Button } from '../../../components/Button';
import { NavigationService } from '../../../services/navigation';
import { Alert } from '../../../services/alert';
import { AuthApi } from '../../../services/api/auth.api';

export const VerifyCode: FC<ICreateAccountProps> = ({
  state,
  setState,
  onSubmitHandler,
}) => {
  const isDesktop = useIsDesktop();
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const screenWidth = Dimensions.get('window').width;

  return (
    <View>
      <View
        style={[
          {
            paddingTop: isDesktop() ? 40 : 0,
            minHeight: 700,
          },
          isDesktop()
            ? {
                maxWidth: MAX_WIDTH,
                width: '100%',
                alignSelf: 'center',
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: 35,
              }
            : {
                paddingHorizontal: 25,
              },
        ]}
      >
        <View style={authStyles.container}>
          <View
            style={[
              isDesktop()
                ? {}
                : {
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginBottom: 10,
                  },
            ]}
          >
            <Image
              source={
                state.mode == 'email' || state.mode == 'reset'
                  ? require('../../../assets/email-icon.png')
                  : require('../../../assets/sms-icon.png')
              }
              style={[
                isDesktop()
                  ? {
                      width: 150,
                      height: 150,
                    }
                  : {
                      width: 103,
                      height: 103,
                    },
              ]}
            />
          </View>
          <Text
            style={[
              authStyles.title,
              isDesktop()
                ? {
                    textAlign: 'left',
                    marginBottom: 10,
                  }
                : {
                    textAlign: 'center',
                    fontSize: 20,
                    marginBottom: 20,
                  },
            ]}
          >
            {state.mode == 'email' || state.mode == 'reset'
              ? 'CHECK YOUR EMAIL'
              : 'CHECK YOUR SMS MESSAGES'}
          </Text>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 20,
              marginBottom: 45,
              textAlign: isDesktop() ? 'left' : 'center',
            }}
          >
            {state.mode == 'email' || state.mode == 'reset'
              ? 'We have sent a security token to your email. Please enter it below.'
              : 'We have sent a security token your mobile number. Please enter the code sent to +852 **** 2181 below.'}
          </Text>
          <Text
            style={{
              fontSize: 19,
              fontFamily: FONTS.AvenirMedium,
              marginBottom: 30,
            }}
          >
            Enter Security Code
          </Text>
          <TextInput
            style={[
              (isDesktop()
                ? STYLE_VARS.textinputweb
                : STYLE_VARS.textinput) as TextStyle,
              ,
              { marginBottom: 30, width: isDesktop() ? 500 : '100%' },
            ]}
            value={code}
            onChangeText={(text) => setCode(text)}
            placeholder=""
          />
          <Text
            style={{
              fontSize: 19,
              fontFamily: FONTS.AvenirMedium,
              marginBottom: 15,
            }}
          >
            Create A Password
          </Text>
          <TextInput
            style={[
              (isDesktop()
                ? STYLE_VARS.textinputweb
                : STYLE_VARS.textinput) as TextStyle,
              ,
              { marginBottom: 30, width: isDesktop() ? 500 : '100%' },
            ]}
            value={password}
            onChangeText={(text) => setPassword(text)}
            placeholder=""
          />
          <View
            style={{
              marginBottom: 30,
            }}
          >
            <Text style={styles.validationPwText}>
              {password.length >= 8 ? '✓ ' : '✕ '}
              At least 8 characters
            </Text>

            <Text style={styles.validationPwText}>
              {/[A-Z]/.test(password) ? '✓ ' : '✕ '}
              At least one capital letter
            </Text>

            <Text style={styles.validationPwText}>
              {/[0-9\W_]/.test(password) ? '✓ ' : '✕ '}
              Contains a number or a special character
            </Text>
          </View>
          <Text
            style={{
              fontSize: 19,
              fontFamily: FONTS.AvenirMedium,
              marginBottom: 30,
            }}
          >
            Re-Enter Password
          </Text>
          <View
            style={{
              marginBottom: 30,
            }}
          >
            <TextInput
              style={[
                (isDesktop()
                  ? STYLE_VARS.textinputweb
                  : STYLE_VARS.textinput) as TextStyle,
                ,
                { marginBottom: 30, width: isDesktop() ? 500 : '100%' },
              ]}
              value={rePassword}
              onChangeText={(text) => setRePassword(text)}
              placeholder=""
            />
            <Text style={styles.validationPwText}>
              {rePassword === password ? '✓ ' : '✕ '}
              Passwords match
            </Text>
          </View>

          <View>
            <Button
              style={[
                styles.buttonStyle,
                !isDesktop() && {
                  width: screenWidth - 40,
                },
              ]}
              className=""
              arrowRight={false}
              textStyle={styles.textButtonStyle}
              text="Submit"
              onPress={() => {
                if (onSubmitHandler) {
                  onSubmitHandler({
                    email: state.email,
                    security_code: code,
                    password: password,
                    password_confirmation: rePassword,
                  });
                }
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default VerifyCode;

const styles = StyleSheet.create({
  validationPwText: {
    fontFamily: FONTS.AvenirMedium,
    fontSize: 16,
    fontWeight: '500',
  },
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 15,
    borderRadius: 13,
    width: 300,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
