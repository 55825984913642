import { FC, useRef, useState } from 'react';
import { Animated, Image, Text, TouchableOpacity, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import MonthSwitcher from '../../../components/MonthSwitcher/MonthSwitcher';
import { IActivities } from '../../../types/activities.types';
import dayjs from 'dayjs';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';

const activities: IActivities[] = [
  {
    id: 1,
    img: '../../../assets/activities-image.png',
    date: '05.11.2024',
    title: 'Bridge & Boccia Competition',
    hour: '10:00 am - 5.00 pm',
    place: 'Tseung Kwan O Sports Center',
  },
  {
    id: 2,
    img: '../../../assets/activities-image.png',
    date: '05.11.2024',
    title: 'Bridge & Boccia Competition',
    hour: '10:00 am - 5.00 pm',
    place: 'Tseung Kwan O Sports Center',
  },
  {
    id: 3,
    img: '../../../assets/activities-image.png',
    date: '05.11.2024',
    title: 'Bridge & Boccia Competition',
    hour: '10:00 am - 5.00 pm',
    place: 'Tseung Kwan O Sports Center',
  },
  {
    id: 4,
    img: '../../../assets/activities-image.png',
    date: '05.11.2024',
    title: 'Bridge & Boccia Competition',
    hour: '10:00 am - 5.00 pm',
    place: 'Tseung Kwan O Sports Center',
  },
];

const ActivityTabItem: FC<{
  active: boolean;
  onPress: () => void;
  title: string;
}> = ({ active, onPress, title }) => {
  const isDesktop = useIsDesktop();
  const [isHover, setHover] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;
  const handleMouseEnter = () => {
    setHover(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseLeave = () => {
    setHover(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  if (isDesktop()) {
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          width: '30%',
          padding: 10,
          backgroundColor:
            isHover || active
              ? COLORS.RED_PRIMARY
              : isDesktop()
              ? COLORS.LIGHT_GREY
              : COLORS.WHITE,
          borderRadius: 10,
        }}
        onClick={onPress}
      >
        <View>
          <Text
            style={{
              flex: 1,
              fontFamily: FONTS.AvenirMedium,
              fontSize: isDesktop() ? 19 : 15,
              color:
                isHover || active
                  ? COLORS.WHITE
                  : isDesktop()
                  ? COLORS.GREY
                  : COLORS.BLACK,
              textAlign: 'center',
            }}
          >
            {title}
          </Text>
        </View>
      </div>
    );
  }

  return (
    <TouchableOpacity
      style={{
        width: '30%',
        padding: 10,
        backgroundColor:
          isHover || active
            ? COLORS.RED_PRIMARY
            : isDesktop()
            ? COLORS.LIGHT_GREY
            : COLORS.WHITE,
        borderRadius: 10,
      }}
      onPress={onPress}
    >
      <View>
        <Text
          style={{
            flex: 1,
            fontFamily: FONTS.AvenirMedium,
            fontSize: isDesktop() ? 19 : 15,
            color:
              isHover || active
                ? COLORS.WHITE
                : isDesktop()
                ? COLORS.GREY
                : COLORS.BLACK,
            textAlign: 'center',
          }}
        >
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export const Activities: FC = () => {
  const [activityTab, setActivityTab] = useState<string>('today');
  const isDesktop = useIsDesktop();
  return (
    <View
      style={[
        isDesktop()
          ? {}
          : {
              paddingHorizontal: 20,
              backgroundColor: '#F9F1F1',
            },
      ]}
    >
      <View>
        <Text
          style={[
            {
              fontFamily: FONTS.AvenirMedium,
              color: COLORS.BLACK,
              textTransform: 'uppercase',
              letterSpacing: 1.5,
              marginBottom: 20,
              marginTop: 10,
            },
            isDesktop()
              ? {
                  fontSize: 24,
                }
              : {
                  fontSize: 18,
                  fontWeight: '700',
                },
          ]}
        >
          UPCOMING ACTIVITIES
        </Text>
        <View
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 10,
              overflow: 'hidden',
              backgroundColor: '#F5F5F5',
              marginBottom: 30,
            },
            isDesktop()
              ? {
                  backgroundColor: '#F5F5F5',
                }
              : {
                  backgroundColor: COLORS.WHITE,
                },
          ]}
        >
          <ActivityTabItem
            active={activityTab == 'today'}
            onPress={() => {
              setActivityTab('today');
            }}
            title="Today"
          />
          <ActivityTabItem
            active={activityTab == 'thisWeek'}
            onPress={() => {
              setActivityTab('thisWeek');
            }}
            title="This Week"
          />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40%',
            }}
          >
            <MonthSwitcher />
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {activities.map((activity, index) => (
            <View
              style={{
                width: '50%',
                flexDirection: 'row',
                justifyContent: index % 2 == 0 ? 'flex-start' : 'flex-end',
                marginBottom: 40,
              }}
            >
              <View
                style={[
                  {
                    borderRadius: 10,
                    overflow: 'hidden',
                  },
                  isDesktop()
                    ? {
                        width: '95%',
                        shadowColor: COLORS.BLACK,
                        shadowOffset: {
                          width: 0,
                          height: 1,
                        },
                        shadowOpacity: 0.5,
                        shadowRadius: 3,
                        elevation: 3,
                      }
                    : {
                        width: '90%',
                      },
                ]}
              >
                <View
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <Image
                    source={require('../../../assets/activities-image.png')}
                    style={[
                      {
                        width: '100%',
                      },
                      isDesktop()
                        ? {
                            height: 250,
                          }
                        : {
                            height: 170,
                            borderRadius: 10,
                          },
                    ]}
                  />
                  <View
                    style={{
                      padding: 7,
                    }}
                  >
                    <View
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Image
                        source={require('../../../assets/heart-icon.png')}
                        style={{
                          width: 14,
                          height: 12,
                          marginRight: 5,
                        }}
                      />
                      <Text
                        style={{
                          fontFamily: FONTS.AvenirMedium,
                          fontSize: isDesktop() ? 16 : 14,
                          color: isDesktop() ? COLORS.GREY : COLORS.BLACK,
                        }}
                      >
                        {activity.date}
                      </Text>
                    </View>
                    <Text
                      style={[
                        {
                          fontFamily: FONTS.AvenirMedium,
                          fontSize: 19,
                          color: COLORS.BLACK,
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                        },
                        !isDesktop() && {
                          lineHeight: 26,
                          marginBottom: 5,
                        },
                      ]}
                    >
                      {activity.title}
                    </Text>
                    <View
                      style={{
                        height: 2,
                        width: 58,
                        backgroundColor: COLORS.SWEET_RED,
                        marginBottom: 4,
                      }}
                    ></View>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: isDesktop() ? 16 : 15,
                        color: COLORS.GREY,
                      }}
                    >
                      {dayjs(activity.date).format('MMMM d, YYYY')}
                    </Text>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: isDesktop() ? 16 : 15,
                        color: COLORS.GREY,
                      }}
                    >
                      {activity.hour}
                    </Text>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: isDesktop() ? 16 : 15,
                        color: COLORS.GREY,
                      }}
                    >
                      {activity.place}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};
